export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'Kontak Email',
    provide_email:
      'Fornisci il tuo indirizzo email per ricevere la notifica di pagamento',
    email_place_holder: 'Masukkan alamat emailmu',
    email_address: 'Alamat email',
    tip: 'Alamat email akan menjadi satu-satunya cara bagi Anda untuk menerima pemberitahuan pembayaran dan pemrosesan pesanan, harap isi dengan hati-hati',
    submit: 'Kirim',
    receiveNotify: 'menerima pemberitahuan',
  },
  // 支付页面
  pay: {
    confirm: 'Tentukan',
    cancel: 'Batal',
    tip: 'Silakan pilih salah satu opsi mata uang berikut untuk menyelesaikan pembayaran Anda',
    pay_complete_date_tip: 'Silakan bayar dalam waktu yang ditentukan',
    wait_for_paying: 'Menunggu pembayaran',
    order_expire_tip:
      'Pesanan ini akan kedaluwarsa, harap kirimkan pembayaran Anda dalam waktu yang tersisa untuk menyelesaikan pembayaran.',
    noticed: 'Dipahami',
    // 付款详情
    closing_before_pay: 'Pembayaran dalam waktu yang ditentukan',
    payment_address: 'Alamat Pembayaran',
    order_no: 'Nomor pesanan',
    product_name: 'Nama produk',
    total_amount: 'Total harga',
    exchange_rate: 'Kurs',
    subtotal: 'Subtotal',
    miner_fee: 'Biaya penambang harus dibayar untuk pesanan ini',
    network_cost: 'Biaya jaringan',
    payable_amount: 'Jumlah yang harus dibayar',
    // 订单超时
    merchant_order_number: 'Nomor seri pesanan',
    order_serial_number: 'Nomor seri pesanan',
    order_expired: 'Pesanan telah kedaluwarsa',
    order_expired_tip:
      'Pesanan hanya berlaku selama 15 menit. Jika Anda ingin membayar kembali, silakan kembali ke halaman pedagang untuk membayar.',
    try_again: 'Coba lagi',
    recharge_specifications: 'Spesifikasi isi ulang',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Pembayaran berhasil',
    confirmed: 'Dikonfirmasi',
    exchangeRate: 'Kurs',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Waktu pembayaran',
    time_of_confirming: 'Waktu konfirmasi',
    payment_amount: 'Jumlah yang dibayarkan',
    pay_success_tip:
      'Tip: Setelah pembayaran berhasil, Anda harus menunggu konfirmasi akhir pemblokiran. Waktu konfirmasi terkait dengan biaya penambang yang dibayar dan fluktuasi jaringan. Jika biaya penambang dibayar terlalu rendah, mungkin ada situasi yang tidak dapat dikonfirmasi secara efektif',
    underpaid_orders: 'Jumlah yang kurang dibayar',
    overpayment_orders: 'Jumlah yang dibayar lebih',
    underpaid_orders_tip:
      'Sistem mendeteksi kekurangan pembayaran, dan jumlah isi ulang ke akun tergantung pada jumlah akun tertentu!',
    overpayment_orders_tip:
      'Sistem mendeteksi kelebihan pembayaran, dan jumlah isi ulang ke akun tergantung pada jumlah spesifik akun!',
    refund: '退款金额',
    go_back_merchant: 'Kembali ke pedagang',
    return: 'Kembali',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Pembayaran Anda sedang dikonfirmasi',
    pay_confirmed: 'Pembayaran dikonfirmasi',
    trade_complete: 'Transaksi selesai',
    what_happened: 'Bagaimana ini?',
    trad_send_tip:
      'Transaksi Anda telah disiarkan, tetapi belum ada konfirmasi. DPay tidak memiliki uang Anda, dan kami tidak dapat mengontrol kapan transaksi dikonfirmasi di Internet. Biaya penambang yang Anda bayar menentukan kecepatan konfirmasi, dan transaksi dapat mengonfirmasi pembayaran Anda di jaringan dalam waktu normal beberapa menit hingga beberapa jam.',
    trad_success_tip:
      'Tips: Pembayaran telah dikonfirmasi, pesanan telah selesai, dan merchant akan memproses transaksi secepatnya.',
    check_order: 'Periksa pesanan',
    order_amount_insufficient: 'Jumlah pesanan tidak mencukupi',
    receiving_refund: '收取退款',
    order_over_payment: 'Pembayaran lebih dari pesanan',
    pay_tip:
      'Tips: Pembayaran telah dikonfirmasi, pesanan telah selesai, dan merchant akan memproses transaksi secepatnya. Jumlah isi ulang ke akun tergantung pada jumlah akun tertentu.',
  },
  // 复制
  copy: {
    copied: 'Disalin',
    copied2: 'Alamat pembayaran telah disalin',
    order_no: 'Nomor pesanan telah disalin',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'Silakan pilih salah satu opsi mata uang berikut untuk menyelesaikan pembayaran Anda',
  },
  country: {
    ar: 'Arab',
    de: 'Jerman',
    en: 'Inggris',
    es: 'Orang Spanyol',
    fr: 'Perancis',
    hi: 'Hindi',
    id: 'Bahasa Indonesia',
    it: 'Italia',
    ja: 'Jepang',
    ko: 'Korea',
    pt: 'Portugis',
    ru: 'Rusia',
    th: 'Thai',
    tr: 'Turki',
    vi: 'Orang Vietnam',
    zh_cn: 'Cina',
    zh_hk: 'Cina tradisional',
  },
  errCode: {
    1000000: 'Pengguna/pedagang ID tidak boleh kosong',
    1000001: 'Pengguna/pedagang API-key tidak boleh kosong',
    1000002: 'Satu-satunya nomor pesanan platform pengguna/pedagang tidak boleh kosong',
    1000003: 'Pengguna/pedagang tidak ada, silakan periksa ID atau API-key',
    1000004: 'Pesanan sudah ada, silahkan cek nomor pesanan',
    1000005: 'Pesanan tidak ada, silakan periksa nomor pesanan',
    1000006: 'Silakan periksa parameter konfigurasi',
    1000007: 'Jumlah pembayaran tidak boleh kosong',
    1000008: 'Jumlah pembayaran tidak boleh kurang dari 0',
    1000009: 'Nomor mata uang tidak boleh kosong',
    1000010: 'Tidak ada alamat untuk ditetapkan, silakan hubungi administrator',
    1000011: 'Kesalahan format email',
  },
  header_menu: {
    logout: '退出',
  },
};
