export default {
  // 联系邮箱
  contactEmail: {
    contact_email: '联系邮箱',
    provide_email: '请提供您的电子邮箱来接收付款通知',
    email_place_holder: '请输入您的邮箱地址',
    email_address: '电子邮箱地址',
    tip: '邮箱将是您后续接收付款通知及订单处理的唯一方式，请认真填写',
    submit: '提交',
    receiveNotify: '接收通知',
  },
  // 支付页面
  pay: {
    confirm: '确定',
    cancel: '取消',
    tip: '扫码或将付款详细信息复制粘贴到您的钱包',
    pay_complete_date_tip: '请在规定时间内付款',
    wait_for_paying: '等待付款',
    order_expire_tip: '此订单即将过期，请在剩余的时间内发送您的付款以完成支付',
    noticed: '知道了',
    // 付款详情
    closing_before_pay: '在所规定的时间内付款',
    payment_address: '付款地址',
    order_no: '订单编号',
    product_name: '商品名称',
    total_amount: '总价',
    exchange_rate: '汇率',
    subtotal: '小计',
    miner_fee: '该笔订单需要支付的矿工费',
    network_cost: '网络成本',
    payable_amount: '应付金额',
    // 订单超时
    merchant_order_number: '商户订单编号',
    order_serial_number: '订单流水编号',
    order_expired: '订单已过期',
    order_expired_tip:
      '订单有效时间仅15分钟，如果您希望重新付款，请回到商家页面重新支付。',
    try_again: '再试一次',
    recharge_specifications: '充值的规格',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: '付款成功',
    confirmed: '已确认',
    exchangeRate: '汇率',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: '付款时间',
    time_of_confirming: '确认时间',
    payment_amount: '支付的金额',
    pay_success_tip:
      '提示：付款成功后，需等待区块最终确认，该确认时长与支付的矿工费和网络波动有关，支付过低矿工费可能出现始终无法有效确认的情况',
    underpaid_orders: '少付金额',
    overpayment_orders: '多付金额',
    underpaid_orders_tip: '系统检测少额支付，充值到账数额以具体到账金额为准！',
    overpayment_orders_tip:
      '系统检测超额支付，充值到账数额以具体到账金额为准！',
    refund: '退款金额',
    go_back_merchant: '返回商户',
    return: '返回',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: '您的付款正在确认',
    pay_confirmed: '付款确认中',
    trade_complete: '交易完成',
    what_happened: '这是怎么回事？',
    trad_send_tip:
      '您的交易已被广播，但尚无任何确认。 DPay没有您的钱，我们也无法控制何时在网络上确认交易。 您支付的矿工费决定确认的快慢，交易可能在几分钟到几小时的正常时间内在网络上确认您的付款。',
    trad_success_tip:
      '提示：付款已确认，该笔订单已完成，商户会尽快进行交易处理。',
    check_order: '查看订单',
    order_amount_insufficient: '订单金额不足',
    receiving_refund: '收取退款',
    order_over_payment: '订单超额支付',
    pay_tip:
      '提示：付款已确认，该笔订单已完成，商户会尽快进行交易处理。充值到账数额以具体到账金额为准。',
  },
  // 复制
  copy: {
    copied: '已复制',
    copied2: '付款地址已复制',
    order_no: '订单编号已复制',
  },
  // 币种
  currency: {
    choose_currency_tip: '请从以下货币选项中选择一个以完成您的支付',
  },
  // 国家
  country: {
    ar: '阿拉伯语',
    de: '德语',
    en: '英语',
    es: '西班牙语',
    fr: '法语',
    hi: '印地语',
    id: '印尼语',
    it: '意大利语',
    ja: '日语',
    ko: '韩语',
    pt: '葡萄牙语',
    ru: '俄语',
    th: '泰语',
    tr: '土耳其语',
    vi: '越南语',
    zh_cn: '中文',
    zh_hk: '繁体中文',
  },
  errCode: {
    1000000: '用户/商户ID不能为空',
    1000001: '用户/商户API-key不能为空',
    1000002: '用户/商户平台唯一订单编号不能为空',
    1000003: '用户/商户不存在,请检查ID或API-key',
    1000004: '订单已存在,请检查订单编号',
    1000005: '订单不存在,请检查订单编号',
    1000006: '请检查配置参数',
    1000007: '付款金额不能为空',
    1000008: '付款金额不能小于0',
    1000009: '币种编号不能为空',
    1000010: '暂无地址可分配，请联系管理员',
    1000011: '邮箱格式错误',
  },
  header_menu: {
    logout: '退出',
  },
};
