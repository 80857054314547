export default {
  // 联系邮箱
  contactEmail: {
    contact_email: '連絡先メールアドレス',
    provide_email: '支払い通知を受け取るためのメールアドレスを入力してください',
    email_place_holder: 'メールボックスを入力してください',
    email_address: '電子メールアドレス',
    tip: 'メールアドレスは、支払い通知と注文処理を受け取る唯一の方法です。慎重に入力してください',
    submit: '提出',
    receiveNotify: '通知を受け取る',
  },
  // 支付页面
  pay: {
    confirm: 'OK',
    cancel: 'キャンセル',
    tip: 'コードをスキャンするか、支払いの詳細をコピーしてウォレットに貼り付けます',
    pay_complete_date_tip: '指定時間内にお支払いください',
    wait_for_paying: '入金待ち',
    order_expire_tip:
      'この注文は間もなく期限切れになります。残りの時間内に支払いを送信して、支払いを完了してください。',
    noticed: '了解した',
    // 付款详情
    closing_before_pay: '指定された時間内の支払い',
    payment_address: 'お支払い住所：',
    order_no: '注文番号',
    product_name: '商品名',
    total_amount: '合計金額',
    exchange_rate: '為替レート',
    subtotal: '小計',
    miner_fee: 'この注文に対して支払われる鉱夫の料金',
    network_cost: 'ネットワークコスト',
    payable_amount: '支払額',
    // 订单超时
    merchant_order_number: '販売者の注文番号',
    order_serial_number: '注文シリアル番号',
    order_expired: '注文の有効期限が切れました',
    order_expired_tip:
      '注文は15分間のみ有効です。返済をご希望の場合は、販売者ページに戻って返済してください。',
    try_again: '再試行',
    recharge_specifications: 'チャージ額や方法',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: '支払いの成功',
    confirmed: '確認済み',
    exchangeRate: '為替レート',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: '支払い時間',
    time_of_confirming: '確認時間',
    payment_amount: '払込金額',
    pay_success_tip:
      'ヒント：支払いが成功したら、ブロックの最終確認を待つ必要があります。確認時間は、支払われた鉱夫料金とネットワークの変動に関連しています。鉱夫料金の支払いが低すぎると、効果的に確認できない状況になる可能性があります',
    underpaid_orders: '未払い額',
    overpayment_orders: '過払い額',
    underpaid_orders_tip:
      'システムは過少支払いを検出し、アカウントへのリチャージの金額はアカウントの特定の金額に左右されます！',
    overpayment_orders_tip:
      'システムは過払いを検出し、アカウントへのリチャージの金額はアカウントの特定の金額に左右されます！',
    refund: '退款金额',
    go_back_merchant: '商人に戻る',
    return: '戻る',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'お支払いが確認されています',
    pay_confirmed: '支払いは確認中です',
    trade_complete: 'トランザクションが完了しました',
    what_happened: 'これはどうですか？',
    trad_send_tip:
      '取引はブロードキャストされましたが、まだ確認されていません。 DPayにはあなたのお金がなく、インターネットで取引がいつ確認されるかを制御することはできません。あなたが支払う鉱夫料金は確認の速度を決定し、トランザクションは数分から数時間の通常の時間内にネットワーク上であなたの支払いを確認するかもしれません。',
    trad_success_tip:
      'ヒント：支払いが確認され、注文が完了しました。販売者はできるだけ早く取引を処理します。アカウントへのリチャージの金額は、アカウントの特定の金額によって異なります。',
    check_order: '注文を確認する',
    order_amount_insufficient: '注文金額が不足している',
    receiving_refund: '收取退款',
    order_over_payment: '注文の過払い',
    pay_tip:
      'ヒント：支払いが確認され、注文が完了しました。販売者はできるだけ早く取引を処理します。アカウントへのリチャージの金額は、アカウントの特定の金額によって異なります。',
  },
  // 复制
  copy: {
    copied: 'コピー',
    copied2: '支払い先住所がコピーされました',
    order_no: 'オーダー番号がコピーされました',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'お支払いを完了するには、次の通貨オプションのいずれかを選択してください',
  },
  country: {
    ar: 'アラビア文',
    de: 'ドイツ',
    en: '英文',
    es: 'スペイン文',
    fr: 'フランス文',
    hi: 'ヒンディー語',
    id: 'インドネシア文',
    it: 'イタリア文',
    ja: '日本文',
    ko: '韓国文',
    pt: 'ポルトガル文',
    ru: 'ロシア',
    th: 'タイ文',
    tr: 'トルコ文',
    vi: 'ベトナム文',
    zh_cn: '中国文',
    zh_hk: '繁体字中国文',
  },
  errCode: {
    1000000: 'ユーザー/マーチャントIDを空にすることはできません',
    1000001: 'ユーザー/マーチャントAPI-keyを空にすることはできません',
    1000002: 'ユーザー/マーチャントプラットフォームの唯一の注文番号を空にすることはできません',
    1000003: 'ユーザー/マーチャントが存在しません。IDまたはAPI-keyを確認してください',
    1000004: '注文はすでに存在します。注文番号を確認してください',
    1000005: '注文が存在しません。注文番号を確認してください',
    1000006: '構成パラメータを確認してください',
    1000007: 'お支払い金額を空にすることはできません',
    1000008: 'お支払い金額は0未満にすることはできません',
    1000009: '通貨番号を空にすることはできません',
    1000010: '割り当てるアドレスがありません。管理者に連絡してください',
    1000011: 'メールフォーマットエラー',
  },
  header_menu: {
    logout: '退出',
  },
};
