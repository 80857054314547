<template>
  <div class="dsf jcsb aic switchLanguage">
    <div class="logo dsf aic">
      <van-image
        width="0.64rem"
        height="0.64rem"
        fit="contain"
        :src="switchLanguageIcon.default"
      />
      <span>DPAY</span>
    </div>


    <div class="language dsf aic jcc" @click="showLanguagePicker">
      <img src="./../assets/imgs/earth.png" alt="" class="earth">
      <span>{{ curLanguageText }}</span>
      <img src="./../assets/imgs/arrow.png" alt="" class="arrow"/>
    </div>

    <van-popup v-model:show="showPicker" position="bottom" class="popup-language" @closed="popupLanguageClosed"
               teleport="#app">
      <van-picker
        :columns="option"
        :default-index="pickerDefaultIndex"
        item-height="1rem"
        ref="pickerRef"
      >
        <template #toolbar>
          <div class="dsf jcsb aic operation-bar">
            <div @click="showPicker = false">{{ t('pay.cancel') }}</div>

            <div @click="confirmHandle" class="confirm">{{ t('pay.confirm') }}</div>
          </div>
        </template>
      </van-picker>
    </van-popup>
  </div>

</template>

<script lang="ts" setup>
import {
  defineComponent,
  ref,
  inject,
  getCurrentInstance,
  watch,
  onMounted, nextTick,
} from 'vue';
import { vantLocales, langKeyOptions } from './../locale';
import { useStore } from 'vuex';
import { LANGUAGE_TYPE } from './../store/mutation-types';
import { useI18n } from 'vue-i18n';
import '@vant/touch-emulator';

    // 获取路径方法
    const $utils: any = inject('$utils');
    // 获取图联系邮箱的路径
    const switchLanguageIcon = $utils.getFilePath('imgs', 'logo_reaipay@2x');
    const { proxy }: any = getCurrentInstance();

    // const { navigator }: any = window

    // (navigator.language || navigator?.browserLanguage || navigator.userLanguage).toLowerCase();
    // console.log('1111',navigator)
    // 初始化和存储
    const store = useStore();
    const languageType = ref(JSON.parse(sessionStorage.getItem('language')));
    let languageTypeText = ref('');
    let option = ref([]);
    const { t } = useI18n();
    const useIn18 = (val: string) => {
      if (!val) return;
      sessionStorage.setItem('language', JSON.stringify(val));
      store.commit(LANGUAGE_TYPE, val);
      proxy.$i18n.locale = val;
      vantLocales(val);
      option.value = langKeyOptions.map(({ value }) => {
        return {
          value,
          text: t(`country.${value}`),
        };
      });
      document.getElementsByTagName('html')[0].style.direction =
        val == 'ar' ? 'rtl' : '';
      languageTypeText.value = t(`country.${val}`);
    };
    watch(languageType, (newVal: any) => {
      useLanguage(newVal);
    });
    onMounted(() => {
      useLanguage(languageType.value);
    });

const showPicker = ref(false);
const curLanguageText = ref();
const pickerRef = ref(null);
const pickerDefaultIndex = ref();

function showLanguagePicker() {
  showPicker.value = true;

  pickerDefaultIndex.value = option.value.findIndex(item => item.text === curLanguageText.value);
}

function confirmHandle() {
  showPicker.value = false;
}

// 使用 closed 方法，不然 picker 会在 popup 关闭之前选回之前的
function popupLanguageClosed() {
  const { value } = pickerRef.value?.getValues()[0];
  useLanguage(value);
}

function useLanguage(val) {
  useIn18(val);

  nextTick(() => {
    const item = option.value.find(item => item.value === val);

    curLanguageText.value = item.text;
  });
}

</script>

<style lang="scss" scoped>
.switchLanguage {
  height: 1.2rem;
  padding: 0.25rem 0.34rem;
  width: 7.5rem;
  margin: 0 auto;

  .logo {
    span {
      padding: 0.23rem;
      color: #262626;
      font-size: 0.34rem;
      font-weight: 600;
    }
  }

  .language {
    cursor: pointer;

    .earth {
      width: 0.28rem;
      height: 0.28rem;
    }

    .arrow {
      width: 0.14rem;
      height: 0.14rem;
    }

    span {
      padding: 0 0.1rem;
      color: #595959;
      font-size: 0.28rem;
    }
  }
}

.operation-bar {
  color: #8C8C8C;
  padding: 0.4rem 0.4rem 0.2rem 0.4rem;
  font-size: 0.32rem;
  width: 7.5rem;

  div {
    cursor: pointer;
  }

  .confirm {
    color: #00D8B1;
    font-weight: 600;
  }
}



</style>
<style lang="scss">
.popup-language {
  border-radius: 0.3rem 0.3rem 0 0;

  .van-picker-column {
  }

  .van-picker-column__item {
    font-size: 0.3rem;
    color: #8C8C8C;
  }

  .van-picker-column__item--selected {
    color: #262626;
  }
}
</style>
