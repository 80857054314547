export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'E-mail de contato',
    provide_email:
      'Favor fornecer seu endereço de e-mail para receber notificações de pagamento',
    email_place_holder: 'Digite sua caixa postal',
    email_address: 'Endereço de e-mail',
    tip: 'Seu e-mail é a única maneira de receber posteriormente notificações de pagamento e processamento de pedidos. Então, favor preencha o formulário cuidadosamente.',
    submit: 'Enviar',
    receiveNotify: 'Receber notificações',
  },
  // 支付页面
  pay: {
    confirm: 'OK',
    cancel: 'abolir',
    tip: 'Digitalize o código para pagar ou copie e cole os detalhes do pagamento em sua carteira',
    pay_complete_date_tip:
      'Favor completar o pagamento dentro do tempo especificado',
    wait_for_paying: 'À espera de pagamento',
    order_expire_tip:
      'Este pedido está prestes a expirar, favor completar o pagamento dentro do tempo restante.',
    noticed: 'Entendo',
    // 付款详情
    closing_before_pay:
      'Favor completar o pagamento dentro do tempo especificado',
    payment_address: 'Endereço para pagamento',
    order_no: 'Número do pedido',
    product_name: 'Nome do produto',
    total_amount: 'Preço total',
    exchange_rate: 'Taxas de câmbio',
    subtotal: 'Subtotal',
    miner_fee: 'Taxa do mineiro a ser paga por este pedido',
    network_cost: 'Custos de rede',
    payable_amount: 'Montante devido',
    // 订单超时
    merchant_order_number: 'Número do pedido do comerciante',
    order_serial_number: 'Linha de pedido nº',
    order_expired: 'Este pedido expirou',
    order_expired_tip:
      'Os pedidos são válidos por 15 minutos, se você desejar pagar novamente favor retornar à página do comerciante.',
    try_again: 'Tente novamente',
    recharge_specifications: 'Especificação de recarga',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Pagamento bem sucedido',
    confirmed: 'Confirmado',
    exchangeRate: 'Taxas de câmbio',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Tempo de pagamento',
    time_of_confirming: 'Tempo de confirmado',
    payment_amount: 'Quantia paga',
    pay_success_tip:
      'Dica: Após o pagamento bem sucedido, você precisa esperar pela confirmação final do bloco, o tempo de espera está relacionado com a taxa paga pelo mineiro e as flutuações da rede, se a taxa paga pelo mineiro for muito baixa, não haverá situação de confirmação',
    underpaid_orders: 'Pagamentos em falta',
    overpayment_orders: 'Pagamentos em excesso',
    underpaid_orders_tip:
      'O sistema detectou um pagamentos em falta, o valor da recarga está sujeito ao valor exato recebido',
    overpayment_orders_tip:
      'O sistema detectou um pagamentos em excesso, o valor da recarga está sujeito ao valor exato recebido',
    refund: '退款金额',
    go_back_merchant: 'Voltar para comerciante',
    return: 'Retornar',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Seu pagamento está sendo confirmado',
    pay_confirmed: 'Confirmação de pagamento em andamento',
    trade_complete: 'Conclusão da transação',
    what_happened: 'O que está acontecendo?',
    trad_send_tip:
      'Sua transação foi transmitida, mas ainda não há confirmação. DPay não tem seu dinheiro e nós não temos controle sobre quando a rede pode confirmar uma transação. A taxa do mineiro que você paga determina o tempo de confirmação e o processo de confirmação de uma transação normalmente leva entre alguns minutos e algumas horas.',
    trad_success_tip:
      'Dica: O pagamento foi confirmado, o pedido foi concluído. O comerciante será processado o mais rápido possível.',
    check_order: 'Ver pedidos',
    order_amount_insufficient: 'Quantidade insuficiente de pedidos',
    receiving_refund: '收取退款',
    order_over_payment: 'Pagamento excessivo do pedido',
    pay_tip:
      'Dica: O pagamento foi confirmado, o pedido foi concluído. O comerciante será processado o mais rápido possível e o valor da recarga está sujeito ao valor exato recebido.',
  },
  // 复制
  copy: {
    copied: 'Copiado',
    copied2: 'Endereço de pagamento copiado',
    order_no: 'Número do pedido copiado',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'Favor selecionar uma das seguintes moedas para completar seu pagamento.',
  },
  country: {
    ar: 'Árabe',
    de: 'Alemão',
    en: 'Inglês',
    es: 'Espanhol',
    fr: 'Francês',
    hi: 'Hindi',
    id: 'Indonésio',
    it: 'Italiano',
    ja: 'Japonês',
    ko: 'Coreano',
    pt: 'Português',
    ru: 'Russo',
    th: 'Tailandês',
    tr: 'Turco',
    vi: 'Vietnamita',
    zh_cn: 'Chinês',
    zh_hk: 'Chinês tradicional',
  },
  errCode: {
    1000000: 'O campo do usuário/comerciante ID não pode estar vazio.',
    1000001: 'O campo do usuário/comerciante API-key não pode estar vazio.',
    1000002: 'O número de pedido único da plataforma do usuário/comerciante não pode estar vazio',
    1000003: 'O usuário/comerciante não existe, favor verificar ID ou API-key.',
    1000004: 'O pedido já existe, favor verificar o número do pedido.',
    1000005: 'O pedido não existe, favor verificar o número do pedido.',
    1000006: 'Por favor, verifique os parâmetros de configuração.',
    1000007: 'O valor do pagamento não pode estar vazio.',
    1000008: 'O valor do pagamento não pode ser inferior a 0.',
    1000009: 'O código da moeda não pode estar vazio.',
    1000010: 'Nenhum endereço a ser designado, favor entrar em contato com o administrador.',
    1000011: 'Formato de email incorreto.',
  },
  header_menu: {
    logout: '退出',
  },
};
