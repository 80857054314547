export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'İletişim E-posta',
    provide_email: 'Ödeme bildirimlerini almak için lütfen e-posta adresinizi girin',
    email_place_holder: 'Lütfen e-posta adresinizi girin',
    email_address: 'E-posta adresi',
    tip: 'E-posta adresi, ödeme bildirimlerini ve sipariş işlemeyi almanın tek yolu olacaktır, lütfen dikkatlice doldurun',
    submit: 'Gönder',
    receiveNotify: 'bildirim al',
  },
  // 支付页面
  pay: {
    confirm: 'Onayla',
    cancel: 'İptal',
    tip: 'Kodu tarayın veya ödeme ayrıntılarını kopyalayıp cüzdanınıza yapıştırın',
    pay_complete_date_tip: 'Lütfen belirtilen süre içinde ödeme yapın',
    wait_for_paying: 'Ödeme için bekleniyor',
    order_expire_tip:
      'Bu siparişin süresi dolmak üzere, lütfen ödemeyi tamamlamak için kalan süre içinde ödemenizi gönderin.',
    noticed: 'Anlaşıldı',
    // 付款详情
    closing_before_pay: 'Belirtilen süre içinde ödeme:',
    payment_address: 'Fatura adresi',
    order_no: 'Sipariş numarası',
    product_name: 'Ürün adı',
    total_amount: 'Toplam fiyat',
    exchange_rate: 'Döviz kuru',
    subtotal: 'Ara toplam',
    miner_fee: 'Bu sipariş için ödenecek madenci ücreti',
    network_cost: 'Ağ maliyeti',
    payable_amount: 'Ödenecek tutarlar',
    // 订单超时
    merchant_order_number: 'Satıcı sipariş numarası',
    order_serial_number: 'Sipariş seri numarası',
    order_expired: 'Siparişin süresi doldu',
    order_expired_tip:
      'Sipariş sadece 15 dakika için geçerlidir. Geri ödeme yapmak istiyorsanız, lütfen geri ödemek için satıcı sayfasına dönün.',
    try_again: 'Tekrar deneyin',
    recharge_specifications: 'Şarj Şartname',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Başarılı ödeme',
    confirmed: 'Onaylanmış',
    exchangeRate: 'Döviz kuru',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'ödeme zamanı',
    time_of_confirming: 'Onay zamanı',
    payment_amount: 'Ödenen miktar',
    pay_success_tip:
      'İpucu: Ödeme başarılı olduktan sonra, bloğun nihai onayını beklemeniz gerekir. Onay süresi, ödenen madenci ücreti ve ağ dalgalanmalarıyla ilgilidir. Madenci ücreti çok düşük ödenirse, etkin bir şekilde teyit edilemeyen durumlar olabilir',
    underpaid_orders: 'Az ödenen miktar',
    overpayment_orders: 'Fazla ödenen miktar',
    underpaid_orders_tip:
      'Sistem, eksik ödeme tespit eder ve hesaba yapılan yükleme miktarı, hesabın belirli miktarına tabidir!',
    overpayment_orders_tip:
      'Sistem fazla ödemeyi tespit eder ve hesaba yapılan yükleme miktarı, hesabın belirli miktarına tabidir!',
    refund: '退款金额',
    go_back_merchant: 'Dükkana  geri dön',
    return: 'Geri dönmek',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Ödemeniz hala onaylanıyor',
    pay_confirmed: 'Ödeme hala onaylanıyor',
    trade_complete: 'İşlem tamamlandı',
    what_happened: 'Bu nasıl gidiyor?',
    trad_send_tip:
      "İşleminiz yayınlandı, ancak henüz bir onay yok. DPay'de paranız yok ve işlemin İnternette ne zaman onaylanacağını kontrol edemiyoruz. Ödediğiniz madenci ücreti, onayın hızını belirler ve işlem, ödemenizi ağda birkaç dakika ila birkaç saat gibi normal bir süre içinde onaylayabilir.",
    trad_success_tip:
      'İpucu: Ödeme onaylandı, sipariş tamamlandı ve satıcı işlemi mümkün olan en kısa sürede gerçekleştirecek.',
    check_order: 'Siparişinizi kontrol edin',
    order_amount_insufficient: 'Yetersiz sipariş miktarı',
    receiving_refund: '收取退款',
    order_over_payment: 'Siparişin fazla ödenmesi',
    pay_tip:
      'İpucu: Ödeme onaylandı, sipariş tamamlandı ve satıcı işlemi mümkün olan en kısa sürede gerçekleştirecek. Hesaba yapılan yükleme miktarı, hesabın belirli miktarına tabidir.',
  },
  // 复制
  copy: {
    copied: 'Kopyalandı',
    copied2: 'Ödeme adresi kopyalandı',
    order_no: 'Sipariş numarası zaten kopyalanmış',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'Ödemenizi tamamlamak için lütfen aşağıdaki para birimi seçeneklerinden birini seçin',
  },
  country: {
    ar: 'Arapça',
    de: 'Almanca',
    en: 'ingilizce',
    es: 'İspanyol',
    fr: 'Fransızca',
    hi: 'Hintçe',
    id: 'Endonezya dili',
    it: 'İtalyan',
    ja: 'Japonca',
    ko: 'Koreli',
    pt: 'Portekizce',
    ru: 'Rusça',
    th: 'Tay dili',
    tr: 'Türk',
    vi: 'Vietnam',
    zh_cn: 'Çince',
    zh_hk: 'Geleneksel çince',
  },
  errCode: {
    1000000: 'Kullanıcı/tüccar ID boş olamaz',
    1000001: 'Kullanıcı/tüccar API-key boş olamaz',
    1000002: 'Kullanıcı/tüccar platformunun tek sipariş numarası boş olamaz',
    1000003: 'Kullanıcı/tüccar mevcut değil, lütfen kimliği veya API-key kontrol edin',
    1000004: 'Sipariş zaten mevcut, lütfen sipariş numarasını kontrol edin',
    1000005: 'Sipariş mevcut değil, lütfen sipariş numarasını kontrol edin',
    1000006: 'Lütfen yapılandırma parametrelerini kontrol edin',
    1000007: 'Ödeme tutarı boş olamaz',
    1000008: "Ödeme tutarı 0'dan az olamaz",
    1000009: 'Para birimi numarası boş olamaz',
    1000010: 'Atanacak adres yok, lütfen yönetici ile iletişime geçin.',
    1000011: 'E-posta biçimi hatası',
  },
  header_menu: {
    logout: '退出',
  },
};
