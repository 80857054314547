export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'อีเมลติดต่อ',
    provide_email: 'โปรดระบุที่อยู่อีเมลของคุณเพื่อรับการแจ้งการชำระเงิน',
    email_place_holder: 'ใส่อีเมลของคุณ',
    email_address: 'ที่อยู่อีเมล',
    tip: 'ที่อยู่อีเมลจะเป็นช่องทางเดียวที่คุณจะได้รับการแจ้งเตือนการชำระเงินและการประมวลผลคำสั่งซื้อ โปรดกรอกอย่างระมัดระวัง',
    submit: 'เสนอ',
    receiveNotify: 'รับการแจ้งเตือน',
  },
  // 支付页面
  pay: {
    confirm: 'กำหนด',
    cancel: 'ยกเลิก',
    tip: 'สแกนรหัสหรือคัดลอกและวางรายละเอียดการชำระเงินลงในกระเป๋าเงินของคุณ',
    pay_complete_date_tip: 'กรุณาชำระเงินภายในเวลาที่กำหนด',
    wait_for_paying: 'รอการชำระเงิน',
    order_expire_tip:
      'คำสั่งซื้อนี้กำลังจะหมดอายุ โปรดส่งการชำระเงินของคุณภายในเวลาที่เหลือเพื่อชำระเงินให้เสร็จสิ้น',
    noticed: 'เข้าใจแล้ว',
    // 付款详情
    closing_before_pay: 'ชำระเงินภายในเวลาที่กำหนด',
    payment_address: 'ที่อยู่การชำระเงิน',
    order_no: 'หมายเลขคําสั่งซื้อ',
    product_name: 'ชื่อของสินค้า',
    total_amount: 'ราคารวม',
    exchange_rate: 'อัตราแลกเปลี่ยน',
    subtotal: 'ยอดรวม',
    miner_fee: 'ค่าธรรมเนียมการขุดที่จะต้องจ่ายสำหรับการสั่งซื้อนี้',
    network_cost: 'ค่าใช้จ่ายเครือข่าย',
    payable_amount: 'จำนวนเงินที่ต้องชำระ',
    // 订单超时
    merchant_order_number: 'หมายเลขคำสั่งซื้อของผู้ขาย',
    order_serial_number: 'สั่งซื้อหมายเลขซีเรียล',
    order_expired: 'คำสั่งหมดอายุ',
    order_expired_tip:
      'คำสั่งซื้อมีอายุเพียง 15 นาที หากคุณต้องการชำระคืน โปรดกลับไปที่หน้าร้านค้าเพื่อชำระคืน',
    try_again: 'ลองอีกครั้ง',
    recharge_specifications: 'ข้อกำหนดการเติมเงิน',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'ชำระเงินสำเร็จ',
    confirmed: 'ยืนยันแล้ว',
    exchangeRate: 'อัตราแลกเปลี่ยน',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'เวลาชำระเงิน',
    time_of_confirming: 'เวลายืนยัน',
    payment_amount: 'จำนวนเงินที่จ่าย',
    pay_success_tip:
      'เคล็ดลับ: หลังจากชำระเงินสำเร็จ คุณต้องรอการยืนยันขั้นสุดท้ายของการบล็อก เวลายืนยันเกี่ยวข้องกับค่าธรรมเนียมการขุดและความผันผวนของเครือข่ายที่ชำระ หากจ่ายค่าธรรมเนียมการขุดต่ำเกินไป อาจเป็นสถานการณ์ที่ไม่สามารถยืนยันได้อย่างมีประสิทธิภาพ',
    underpaid_orders: 'จำนวนเงินที่ชำระน้อยไป',
    overpayment_orders: 'จำนวนเงินที่ชำระเกิน',
    underpaid_orders_tip:
      'ระบบตรวจพบการชำระเงินน้อยเกินไปและจำนวนการเติมเงินเข้าบัญชีจะขึ้นอยู่กับจำนวนเฉพาะของบัญชี!',
    overpayment_orders_tip:
      'ระบบจะตรวจจับการชำระเงินเกินและจำนวนการเติมเงินเข้าบัญชีจะขึ้นอยู่กับจำนวนเงินเฉพาะของบัญชี!',
    refund: '退款金额',
    go_back_merchant: 'กลับไปที่ร้านค้า',
    return: 'กลับ',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'กำลังยืนยันการชำระเงินของคุณ',
    pay_confirmed: 'กำลังยืนยันการชำระเงิน',
    trade_complete: 'ธุรกรรมเสร็จสมบูรณ์',
    what_happened: 'เรื่องนี้เป็นอย่างไรบ้าง?',
    trad_send_tip:
      'ธุรกรรมของคุณได้รับการเผยแพร่แล้ว แต่ยังไม่มีการยืนยัน DPay ไม่มีเงินของคุณ และเราไม่สามารถควบคุมได้ว่าเมื่อใดที่ธุรกรรมจะได้รับการยืนยันบนอินเทอร์เน็ต ค่าธรรมเนียมการขุดที่คุณจ่ายกำหนดความเร็วของการยืนยัน และการทำธุรกรรมอาจยืนยันการชำระเงินของคุณในเครือข่ายภายในเวลาปกติไม่กี่นาทีถึงสองสามชั่วโมง',
    trad_success_tip:
      'เคล็ดลับ: การชำระเงินได้รับการยืนยัน คำสั่งซื้อเสร็จสมบูรณ์ และผู้ค้าจะดำเนินการธุรกรรมโดยเร็วที่สุด',
    check_order: 'ตรวจสอบคำสั่งซื้อ',
    order_amount_insufficient: 'ปริมาณการสั่งซื้อไม่เพียงพอ',
    receiving_refund: '收取退款',
    order_over_payment: 'ชำระเงินเกินของการสั่งซื้อ',
    pay_tip:
      'เคล็ดลับ: การชำระเงินได้รับการยืนยัน การสั่งซื้อเสร็จสมบูรณ์ และผู้ค้าจะดำเนินการธุรกรรมโดยเร็วที่สุด จำนวนการเติมเงินเข้าบัญชีขึ้นอยู่กับจำนวนบัญชีเฉพาะ',
  },
  // 复制
  copy: {
    copied: 'คัดลอกแล้ว',
    copied2: 'คัดลอกที่อยู่การชำระเงินแล้ว',
    order_no: 'หมายเลขคําสั่งซื้อถูกคัดลอกแล้ว',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'โปรดเลือกหนึ่งในตัวเลือกสกุลเงินต่อไปนี้เพื่อชำระเงินให้เสร็จสิ้น',
  },
  country: {
    ar: 'อาหรับ',
    de: 'เยอรมัน',
    en: 'ภาษาอังกฤษ',
    es: 'ภาษาสเปน',
    fr: 'ฝรั่งเศส',
    hi: 'ฮินดี',
    id: 'ชาวอินโดนีเซีย',
    it: 'อิตาลี',
    ja: 'ญี่ปุ่น',
    ko: 'เกาหลี',
    pt: 'โปรตุเกส',
    ru: 'รัสเซีย',
    th: 'ไทย',
    tr: 'ตุรกี',
    vi: 'เวียดนาม',
    zh_cn: 'ชาวจีน',
    zh_hk: 'ภาษาจีนตัวเต็ม',
  },
  errCode: {
    1000000: 'ผู้ใช้/พ่อค้า ID ไม่สามารถเว้นว่างได้',
    1000001: 'ผู้ใช้/พ่อค้า API-key ไม่สามารถเว้นว่างได้',
    1000002: 'ต้องระบุหมายเลขคำสั่งซื้อของแพลตฟอร์มผู้ใช้พ่อค้าเท่านั้น',
    1000003: 'ไม่มีผู้ใช้/พ่อค้า โปรดตรวจสอบ ID หรือAPI-key',
    1000004: 'มีคำสั่งซื้อนี้แล้ว โปรดตรวจสอบหมายเลขคำสั่งซื้อ',
    1000005: 'ไม่มีคำสั่งซื้อ โปรดตรวจสอบหมายเลขคำสั่งซื้อ',
    1000006: 'โปรดตรวจสอบพารามิเตอร์การกำหนดค่า',
    1000007: 'จำนวนเงินที่ชำระต้องไม่ว่างเปล่า',
    1000008: 'จำนวนเงินที่ชำระต้องไม่น้อยกว่า 0',
    1000009: 'ต้องระบุหมายเลขสกุลเงิน',
    1000010: 'ไม่มีที่อยู่ที่จะมอบหมาย โปรดติดต่อผู้ดูแลระบบ',
    1000011: 'รูปแบบอีเมลผิดพลาด',
  },
  header_menu: {
    logout: '退出',
  },
};
