import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
// let files: object = import.meta.globEager('./../views/*.vue');

// const routerList = Array.from(Object.keys(files)).map((routePath: any) => {
//   const name = routePath.slice(11, -4);

//   return {
//     path: `/${name}`,
//     name: name,
//     // import 不支持动态导入的形式
//     component: () => import(/* @vite-ignore */ routePath),
//   };
// });
const routerList = [
  {
    path: `/contactEmail`,
    name: 'contactEmail',
    component: () => import('../views/contactEmail/index.vue'),
  },
  {
    path: `/currency`,
    name: 'currency',
    component: () => import('../views/currency/index.vue'),
  },
  {
    path: `/payAddress`,
    name: 'payAddress',
    component: () => import('../views/payAddress/index.vue'),
  },
  {
    path: `/payAddressV2`,
    name: 'payAddressV2',
    component: () => import('../views/payAddress-v2/index.vue'),
  },
  {
    path: `/payResult`,
    name: 'payResult',
    component: () => import('../views/payResult/index.vue'),
  },
  {
    path: `/payAccount`,
    name: 'payAccount',
    component: () => import('../views/payAccount/index.vue'),
  },
  {
    path: `/payAccountEmail`,
    name: 'payAccountEmail',
    component: () => import('../views/payAccount/email.vue'),
  },
  {
    path: `/refund`,
    name: 'refund',
    component: () => import('../views/refund/index.vue'),
  },
];

const defaultRoutes: Array<RouteRecordRaw> = [
  { path: '/', redirect: { path: 'contactEmail' } },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes: [...defaultRoutes, ...routerList],
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    //如果未匹配到路由
    from.name ? next({ name: from.name }) : next({ name: 'contactEmail' });
  } else {
    next();
  }
});

export default router;
