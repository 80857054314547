export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'Correo electrónico de contacto',
    provide_email:
      'Por favor, proporcione su correo electrónico para recibir notificaciones de pago',
    email_place_holder: 'Ingrese su buzón',
    email_address: 'Dirección de correo electrónico',
    tip: 'Su correo electrónico será la única forma de recibir la notificación del pago y la tramitación del pedido, por favor, rellénelo cuidadosamente',
    submit: 'Enviar',
    receiveNotify: 'recibir notificaciones',
  },
  // 支付页面
  pay: {
    confirm: 'ok',
    cancel: 'cancelar',
    tip: 'Escanee el código o copie y pegue los detalles del pago en su cartera',
    pay_complete_date_tip: 'Pago dentro del tiempo especificado',
    wait_for_paying: 'A la espera del pago',
    order_expire_tip:
      'Este pedido está a punto de expirar, por favor envíe su pago dentro del tiempo restante para completar el pago.',
    noticed: 'Ya lo sé',
    // 付款详情
    closing_before_pay: 'Pago dentro del tiempo especificado',
    payment_address: 'Dirección de Pago',
    order_no: 'Número de pedido',
    product_name: 'Nombre del producto',
    total_amount: 'Precio total',
    exchange_rate: 'Tipos de cambio',
    subtotal: 'Subtotal',
    miner_fee: 'Tarifas de minero que deben pagarse por este pedido',
    network_cost: 'Costo de la red',
    payable_amount: 'Cantidad a pagar',
    // 订单超时
    merchant_order_number: 'Número de orden comercial',
    order_serial_number: 'Número de serie del pedido',
    order_expired: 'La orden ha expirado',
    order_expired_tip:
      'El pedido es válido por solo 15 minutos. Si desea reembolsar, regrese a la página del comerciante para reembolsar.',
    try_again: 'Intentar otra vez',
    recharge_specifications: 'Especificaciones de recarga',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Pago exitoso',
    confirmed: 'Se ha confirmado',
    exchangeRate: 'Tipos de cambio',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Tiempo de pago',
    time_of_confirming: 'Confirmo la hora',
    payment_amount: 'Cantidad pagada',
    pay_success_tip:
      'Sugerencia: Después de un pago exitoso, necesita esperar la confirmación final del bloque, el tiempo de confirmación está relacionado con la tasa de minero pagada y las fluctuaciones de la red, una tasa de minero demasiado baja pagada puede que nunca sea confirmada válidamente',
    underpaid_orders: 'Pago insuficiente',
    overpayment_orders: 'Pago en exceso',
    underpaid_orders_tip:
      'El sistema detecta el pago insuficiente y la cantidad de recarga a la cuenta está sujeta a la cantidad específica de la cuenta.',
    overpayment_orders_tip:
      'El sistema detecta el pago en exceso y la cantidad de recarga a la cuenta está sujeta a la cantidad específica de la cuenta.',
    refund: '退款金额',
    go_back_merchant: 'Volver al comerciante',
    return: 'Devolver',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Su pago está siendo confirmado',
    pay_confirmed: 'Confirmación de pago en curso',
    trade_complete: 'Transacción completa',
    what_happened: '¿Cómo va esto?',
    trad_send_tip:
      'Su transacción ha sido emitida, pero aún no hay confirmación. DPay no dispone de su dinero y no tenemos ningún control sobre cuándo se confirma una transacción en la red. La tasa de minero que pagas determina la rapidez con la que se realiza la confirmación y la transacción puede ser confirmada en la red en el plazo normal de unos minutos a unas horas.',
    trad_success_tip:
      'Consejo: El pago ha sido confirmado, el pedido ha sido completado y el comerciante procesará la transacción tan pronto como sea posible.',
    check_order: 'Ver pedidos',
    order_amount_insufficient: 'Importe de pedido insuficiente',
    receiving_refund: '收取退款',
    order_over_payment: 'Orden de pago en exceso',
    pay_tip:
      'Consejo: El pago ha sido confirmado, el pedido ha sido completado y el comerciante procesará la transacción lo antes posible. El importe de la recarga en la cuenta está sujeto al importe específico de llegada de',
  },
  // 复制
  copy: {
    copied: 'Copiado',
    copied2: 'Dirección de pago copiada',
    order_no: 'Número de pedido copiado',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'Por favor, elija una de las siguientes opciones de moneda para completar su pago',
  },
  country: {
    ar: 'Arábica',
    de: 'Alemán',
    en: 'Inglés',
    es: 'Español',
    fr: 'Francés',
    hi: 'hindi',
    id: 'Indonesio',
    it: 'Italiano',
    ja: 'Japonés',
    ko: 'Coreano',
    pt: 'Portugués',
    ru: 'Ruso',
    th: 'Tailandés',
    tr: 'Turco',
    vi: 'Vietnamita',
    zh_cn: 'Chino',
    zh_hk: 'Chino tradicional',
  },
  errCode: {
    1000000: 'El usuario / comerciante ID no puede estar vacío',
    1000001: 'El usuario / comerciante API-key no puede estar vacío',
    1000002: 'El número de orden único de la Plataforma de usuario / comerciante no puede estar vacío',
    1000003: 'El usuario / comerciante no existe, por favor compruebe su ID o API - Key',
    1000004: 'El pedido ya existe, compruebe el número de pedido',
    1000005: 'El pedido no existe, compruebe el número de pedido',
    1000006: 'Compruebe los parámetros de configuración',
    1000007: 'El importe del pago no puede estar vacío',
    1000008: 'El importe del pago no puede ser inferior a 0',
    1000009: 'El número de moneda no puede estar vacío',
    1000010: 'No se puede asignar ninguna dirección, póngase en contacto con el Administrador',
    1000011: 'Error de formato del email',
  },
  header_menu: {
    logout: '退出',
  },
};
