<template>
  <nav>
    <div class="goBackIcon" @click="goBack" v-if="isBack">
      <van-icon name="arrow-left" />
    </div>
    <div class="navCenter">
      <div class="content">
        <van-image
          height="0.41rem"
          width="0.41rem"
          fit="contain"
          :src="getPayInfoData.logo"
          class="img"
        />
        {{ getPayInfoData.merchantName }}
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, inject, computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { baseUrl } from '@/utils/config';

export default defineComponent({
  setup() {
    // // 获取路径方法
    // const { getFilePath }: any = inject('$utils');
    // // 获取图联系邮箱的路径
    // const emailLogo = getFilePath('imgs', 'LOGO_icon@2x')
    // console.log('emailLogo', emailLogo);
    const store = useStore();

    const getPayInfoData = computed(() => store.state.getPayInfoData);
    const orderStatus = computed(() => store.state.orderInfoDetail.status);

    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    };

    const currentRoute = computed(() => router.currentRoute.value);

    const BrowserBack = () => {
      history.pushState(null, null, document.URL);
    };
    const isBack = computed(() => {
      if (
        currentRoute.value.path == '/payResult' &&
        orderStatus.value > 2 &&
        orderStatus.value < 9
      ) {
        // 如果当前在结果页，则将结果页塞入历史记录，使用回退时记录历史
        BrowserBack();
        window.addEventListener('popstate', BrowserBack);
        return false;
      } else {
        // history.go(-1);
        window.removeEventListener('popstate', BrowserBack);
        return true;
      }
    });

    return {
      currentRoute,
      orderStatus,
      isBack,
      getPayInfoData,
      // emailLogo: logo,
      // emailLogo: `${baseUrl}/${logo}`,
      // merchantName,
      goBack,
    };
  },
});
</script>

<style lang="scss">
@import '@/assets/style/global.scss';

nav {
  height: 0.8rem;
  display: flex;
  align-items: center;
  position: relative;
  background: #1A1A1A;
  font-size: 0.34rem;
  color: #FFF;
  .goBackIcon {
    width: 0.5rem;
    position: absolute;
    left: 0.22rem;
    top: 50%;
    margin-top: -0.17rem;
  }
  .navCenter {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // line-height: 0.41rem;
    // margin-left: -0.755rem;
    // margin-top: -0.205rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    //width: calc(100% - 0.34rem);
    .content {
      text-align: center;
      display: flex;
      align-items: center;
      .img {
        overflow: hidden;
        margin-right: 0.15rem;
        margin-left: 0.15rem;
      }
    }
  }
}
</style>
