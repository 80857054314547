export default {
  contactEmail: {
    contact_email: 'Contact E-mail',
    provide_email: 'Please provide your E-maii address for payment order',
    email_place_holder: 'Please enter your email address',
    email_address: 'E-mail Addrerss ',
    tip: 'Email will be the only access for upcoing payment update and and order stub, please write correctly',
    submit: 'Submit',
    receiveNotify: 'Receive Notifications',
  },
  pay: {
    confirm: 'OK',
    cancel: 'Cancel',
    tip: 'Scan code or copy and paste the payment details into your wallet.',
    pay_complete_date_tip: 'Please pay in setting time',
    wait_for_paying: 'Waiting for payment',
    order_expire_tip:
      'You order is about expiring, please process the payment in time to finish this order',
    noticed: 'Awared',
    // 付款详情
    closing_before_pay: 'Please pay by the closing date',
    payment_address: 'Payment address',
    order_no: 'Order number',
    product_name: 'Product name',
    total_amount: 'Total Price',
    exchange_rate: 'Exchange rate',
    subtotal: 'Total Amount',
    miner_fee: 'The miner fee for this order',
    network_cost: 'Gas fee',
    payable_amount: 'Amount Due',
    // 订单超时
    merchant_order_number: 'Business Order Number',
    order_serial_number: 'Customer Order Number',
    order_expired: 'Order has expired',
    order_expired_tip:
      'This order is only valid for 15 minutes. If you want to pay again, please go back to merchant page to pay.',
    try_again: 'Pay again',
    recharge_specifications: 'Recharge specifications',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Payment succeed',
    confirmed: 'Confirmed',
    exchangeRate: 'Exchange rate',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Payment time',
    time_of_confirming: 'Confirm time',
    payment_amount: 'Payment amount',
    pay_success_tip:
      'After payment processed, the time of confirming, you need wait for decentalized blockchain confirmation, may affected by gas fee and website flactuation, the low gas fee may cause the order failed',
    underpaid_orders: 'insufficient payment',
    overpayment_orders: 'overheads',
    underpaid_orders_tip:
      'System detected an insufficient payment, th recharge amount will be asme as actual amount',
    overpayment_orders_tip:
      'System detected an overhead, the recharge amount will be asme as actual amount',
    refund: 'Refund',
    go_back_merchant: 'Back to Business page',
    return: 'Return',
  },
  // 退款页面
  refund: {
    currency_type: 'Currency type',
    processing_fee: 'Processing fee',
    // currency_type:'到账金额',
    blockchain_name: 'Blockchain name',
    address_receiving_refund: 'Address receiving refund',
    address_info_placeholder:
      'Information such as wallet address and exchange',
    blockchain_trade_ID: 'Blockchain trade ID',
    attention: 'Attention',
    check_address_tip:
      'Please make sure you checked address is correct, or there is risk of crypto loss. If you enter or paste the wrong address, you will bear the loss caused by it.',
    notes: 'Notes',
    time: 'Time',
    refunded: 'Refunded',
    refunding: 'Refunding',
  },
  pay_account: {
    pay_confirmed_tip: 'Your payment is confirming',
    pay_confirmed: 'Payment confirming',
    trade_complete: 'Trade completed',
    what_happened: "What's going on?",
    trad_send_tip:
      "Your payment has been broadcasted on Blockchain, but havn't been confirmed yet. Dpay don't own your asset, and we couldn't determine the actual confirming time. You gas fee determined the blockchain speed, the trade may fullfilled normally in minutes to hours depends on blockchain process",
    trad_success_tip:
      'Remind: You payment has been confirmed, the business will submit deal as soon as possible',
    check_order: 'Check orders',
    order_amount_insufficient: 'Order amount is insufficient',
    receiving_refund: 'Receiving refund',
    order_over_payment: 'Order payment overhead',
    pay_tip:
      'Remind: your payment has been confirmed, business will handle the processing as soon as possible, the recharge amount will be asme as actual amount',
  },
  // 复制
  copy: {
    copied: 'Copied',
    copied2: 'Payment address has been copied',
    order_no: 'Order number has been copied.',
  },
  currency: {
    choose_currency_tip:
      'Please choose a currency type from the options down below',
  },
  // 国家
  country: {
    ar: 'Arabic',
    de: 'German',
    en: 'English',
    es: 'Spanish',
    fr: 'French',
    hi: 'Hindi',
    id: 'Indonesian',
    it: 'Italian',
    ja: 'Japanese',
    ko: 'Korean',
    pt: 'Portuguese',
    ru: 'Russian',
    th: 'Thai',
    tr: 'Turkish',
    vi: 'Vietnamese',
    zh_cn: 'Chinese',
    zh_hk: 'Traditional Chinese',
  },
  errCode: {
    1000000: 'User / Merchant ID cannot be empty',
    1000001: 'User / Merchant API-key cannot be empty',
    1000002: 'User/merchant platform unique order number cannot be empty',
    1000003: 'User/merchant does not exist, please check the ID or API-key',
    1000004: 'The order does not exist, please check the order number',
    1000005: 'The order does not exist, please check the order number',
    1000006: 'Please check the configuration parameters',
    1000007: 'Payment amount cannot be empty',
    1000008: 'Payment amount cannot be less than 0',
    1000009: 'The currency number cannot be empty',
    1000010: 'There is no address to assign, please contact the administrator',
    1000011: 'Mailbox format error',
  },
  header_menu: {
    logout: 'logout',
  },
};
