import { createStore } from 'vuex';
import createPersistedstate from 'vuex-persistedstate';
import {
  LANGUAGE_TYPE,
  GET_PAY_INFO_DATA,
  ORDER_INFO_DETAIL,
  CREATED_ORDER_INFO,
  CURRENCY_LOGO,
  USDT_CURRENCY_LIST,
  SET_IS_USDT,
  PAY_AGAIN,
} from './mutation-types';

const defaultState = {
  // count: 0,
  languageType: '',
  getPayInfoData: '',
  orderInfoDetail: '',
  createdOrderInfo: '',
  currencyLogo: '',
  usdtCurrencyList: [],
  isUsdt: false,
  payAgain: false,
};

// Create a new store instance.
export default createStore({
  plugins: [
    createPersistedstate({
      key: 'vuex',
      storage: {
        getItem: (key: string) => sessionStorage.getItem(key),
        setItem: (key: string, value: any) =>
          sessionStorage.setItem(key, value),
        removeItem: (key: string) => sessionStorage.removeItem(key),
      },
    }),
  ],
  state() {
    return defaultState;
  },
  mutations: {
    // increment(state: typeof defaultState) {
    //   state.count += 1
    // }
    [LANGUAGE_TYPE](state: typeof defaultState, payload) {
      state.languageType = payload;
    },
    [GET_PAY_INFO_DATA](state: typeof defaultState, payload) {
      state.getPayInfoData = payload;
    },
    [ORDER_INFO_DETAIL](state: typeof defaultState, payload) {
      state.orderInfoDetail = payload;
    },
    [CREATED_ORDER_INFO](state: typeof defaultState, payload) {
      state.createdOrderInfo = payload;
    },
    [CURRENCY_LOGO](state: typeof defaultState, payload) {
      state.currencyLogo = payload;
    },
    [USDT_CURRENCY_LIST](state: typeof defaultState, payload) {
      state.usdtCurrencyList = payload;
    },
    [SET_IS_USDT](state: typeof defaultState, payload) {
      state.isUsdt = payload;
    },
    [PAY_AGAIN](state: typeof defaultState, payload) {
      state.payAgain = payload;
    }
  },
  actions: {
    // increment(context) {
    //   context.commit('increment')
    // }
  },
  getters: {
    // double(state: typeof defaultState) {
    //   return 2 * state.count
    // }
  },
});
