export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'Kontakt E-mail',
    provide_email:
      'Bitte geben Sie Ihre E-Mail-Adresse an, um eine Zahlungsbenachrichtigung zu erhalten',
    email_place_holder: 'Geben Sie Ihre Mailbox ein',
    email_address: 'Bitte wählen Sie den Emotionsstatus',
    tip: 'Die E-Mail-Adresse ist die einzige Möglichkeit für Sie, Zahlungsbenachrichtigungen und Bestellabwicklung zu erhalten, bitte sorgfältig ausfüllen',
    submit: 'Einreichen',
    receiveNotify: 'Benachrichtigungen erhalten',
  },
  // 支付页面
  pay: {
    confirm: 'OK',
    cancel: 'Abbrechen',
    tip: 'Scannen Sie den Code oder kopieren Sie die Zahlungsdetails und fügen Sie sie in Ihre Brieftasche ein',
    pay_complete_date_tip: 'Bitte zahlen Sie innerhalb der angegebenen Zeit',
    wait_for_paying: 'Warten auf Zahlung',
    order_expire_tip:
      'Diese Bestellung läuft bald ab. Bitte senden Sie Ihre Zahlung innerhalb der verbleibenden Zeit, um die Zahlung abzuschließen.',
    noticed: 'Verstanden',
    // 付款详情
    closing_before_pay: 'Bitte zahlen Sie innerhalb der angegebenen Zeit',
    payment_address: 'Zahlungsadresse',
    order_no: 'Bestellnummer',
    product_name: 'Produktname',
    total_amount: 'Gesamtpreis',
    exchange_rate: 'Tauschrate',
    subtotal: 'Zwischensumme',
    miner_fee: 'Die für diese Bestellung zu zahlende Bergmannsgebühr',
    network_cost: 'Netzwerkkosten',
    payable_amount: 'Zu zahlende Beträge',
    // 订单超时
    merchant_order_number: 'Bestellnummer des Händlers',
    order_serial_number: 'Seriennummer der Bestellung',
    order_expired: 'Die Bestellung ist abgelaufen',
    order_expired_tip:
      'Die Bestellung ist nur 15 Minuten gültig. Wenn Sie eine Rückerstattung wünschen, kehren Sie bitte zur Händlerseite zurück, um eine Rückerstattung zu erhalten.',
    try_again: 'Versuchen Sie es nochmal',
    recharge_specifications: 'Spezifikationen zum Aufladen',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Erfolgreiche Zahlung',
    confirmed: 'Bestätigt',
    exchangeRate: 'Tauschrate',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Zahlungszeit',
    time_of_confirming: 'Bestätigungszeit',
    payment_amount: 'Bezahlter Betrag',
    pay_success_tip:
      'Tipp: Nach erfolgreicher Zahlung müssen Sie auf die endgültige Bestätigung der Sperre warten. Die Bestätigungszeit hängt von der bezahlten Miner-Gebühr und den Netzwerkschwankungen ab. Wenn die Miner-Gebühr zu niedrig bezahlt wird, kann es zu Situationen kommen, die nicht effektiv bestätigt werden können',
    underpaid_orders: 'Unterbezahlter Betrag',
    overpayment_orders: 'Überzahlter Betrag',
    underpaid_orders_tip:
      'Das System erkennt eine Unterzahlung und die Höhe der Aufladung des Kontos richtet sich nach dem spezifischen Betrag des Kontos!',
    overpayment_orders_tip:
      'Das System erkennt eine Überzahlung und die Höhe der Aufladung des Kontos richtet sich nach dem spezifischen Betrag des Kontos!',
    refund: '退款金额',
    go_back_merchant: 'Zurück zum Händler',
    return: 'Zurückkehren',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Ihre Zahlung wird noch bestätigt',
    pay_confirmed: 'Zahlung wird noch bestätigt',
    trade_complete: 'Transaktion abgeschlossen',
    what_happened: 'Wie geht das?',
    trad_send_tip:
      'Ihre Transaktion wurde ausgeführt, aber es gibt noch keine Bestätigung. DPay hat Ihr Geld nicht und wir haben keine Kontrolle darüber, wann eine Transaktion im Netzwerk bestätigt wird. Die von Ihnen bezahlte Miner-Gebühr bestimmt, wie schnell die Bestätigung erfolgt und die Transaktion innerhalb des normalen Zeitraums von wenigen Minuten bis wenigen Stunden im Netzwerk bestätigt werden kann.',
    trad_success_tip:
      'Tipp: Die Zahlung wurde bestätigt, die Bestellung ist abgeschlossen und der Händler wird die Transaktion so schnell wie möglich bearbeiten.',
    check_order: 'Prüfe die Reihenfolge',
    order_amount_insufficient: 'Unzureichende Bestellmenge',
    receiving_refund: '收取退款',
    order_over_payment: 'Überzahlung der Bestellung',
    pay_tip:
      'Tipp: Die Zahlung wurde bestätigt, die Bestellung ist abgeschlossen und der Händler wird die Transaktion so schnell wie möglich bearbeiten. Die Höhe der Aufladung des Kontos richtet sich nach dem spezifischen Betrag des Kontos.',
  },
  // 复制
  copy: {
    copied: 'Kopiert',
    copied2: 'Zahlungsadresse kopiert',
    order_no: 'Auftragsnummer kopiert',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'Bitte wählen Sie eine der folgenden Währungsoptionen aus, um Ihre Zahlung abzuschließen',
  },
  country: {
    ar: 'Arabisch',
    de: 'Deutsche',
    en: 'Englisch',
    es: 'Spanisch',
    fr: 'Französisch',
    hi: 'Hindi',
    id: 'Indonesisch',
    it: 'Italienisch',
    ja: 'Japanisch',
    ko: 'Koreanisch',
    pt: 'Portugiesisch',
    ru: 'Russisch',
    th: 'Thai',
    tr: 'Türkisch',
    vi: 'Vietnamesisch',
    zh_cn: 'Chinesisch',
    zh_hk: 'Taiwan, China',
  },
  errCode: {
    1000000: 'Benutzer/Händler ID darf nicht leer sein',
    1000001: 'Benutzer/Händler API-key darf nicht leer sein',
    1000002: 'Die einzige Bestellnummer der Benutzer-/Händlerplattform darf nicht leer sein',
    1000003: 'Benutzer/Händler existiert nicht, bitte ID oder API-key überprüfen',
    1000004: 'Die Bestellung existiert bereits, bitte überprüfen Sie die Bestellnummer',
    1000005: 'Die Bestellung existiert nicht, bitte überprüfen Sie die Bestellnummer',
    1000006: 'Bitte überprüfen Sie die Konfigurationsparameter',
    1000007: 'Zahlungsbetrag darf nicht leer sein',
    1000008: 'Zahlungsbetrag darf nicht kleiner als 0 . sein',
    1000009: 'Währungsnummer darf nicht leer sein',
    1000010: 'Es gibt keine Adresse zum Zuweisen, bitte kontaktieren Sie den Administrator',
    1000011: 'Fehler im E-Mail-Format',
  },
  header_menu: {
    logout: '退出',
  },
};
