export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'Почта для связи',
    provide_email:
      'Пожалуйста, укажите свой адрес электронной почты, чтобы получать уведомление о платеже',
    email_place_holder: 'Введите свой почтовый ящик',
    email_address: 'Адрес электронной почты',
    tip: 'Электронный адрес будет единственным способом получать уведомления об оплате и обработку заказа, пожалуйста, заполните внимательно',
    submit: 'Отправить',
    receiveNotify: 'получать уведомления',
  },
  // 支付页面
  pay: {
    confirm: 'ХОРОШО',
    cancel: 'Отмена',
    tip: 'Отсканируйте код или скопируйте и вставьте реквизиты платежа в свой кошелек',
    pay_complete_date_tip: 'Пожалуйста, оплатите в течение указанного времени',
    wait_for_paying: 'Oжидается платеж',
    order_expire_tip:
      'Срок действия этого заказа скоро истечет, отправьте платеж в течение оставшегося времени, чтобы завершить платеж.',
    noticed: 'Понял',
    // 付款详情
    closing_before_pay: 'Оплата в указанные сроки',
    payment_address: 'Платежный адрес',
    order_no: 'Номер заказа',
    product_name: 'Товарное название',
    total_amount: 'Итоговая цена',
    exchange_rate: 'Обменный курс',
    subtotal: 'Промежуточный итог',
    miner_fee: 'Гонорар майнера к оплате за этот заказ',
    network_cost: 'Стоимость сети',
    payable_amount: 'Суммы к оплате',
    // 订单超时
    merchant_order_number: 'Номер заказа продавца',
    order_serial_number: 'Серийный номер заказа',
    order_expired: 'Срок действия заказа истек',
    order_expired_tip:
      'Заказ действителен всего 15 минут. Если вы хотите погасить, пожалуйста, вернитесь на страницу продавца для погашения.',
    try_again: 'Попробуйте снова',
    recharge_specifications: 'Характеристики перезарядки',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Успешный платеж',
    confirmed: 'Подтвержденный',
    exchangeRate: 'Обменный курс',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Время оплаты',
    time_of_confirming: 'Время подтверждения',
    payment_amount: 'Оплаченная сумма',
    pay_success_tip:
      'Совет: После успешной оплаты нужно дождаться окончательного подтверждения блокировки. Время подтверждения связано с оплаченной комиссией майнера и колебаниями сети. Если комиссия майнера уплачена слишком низко, могут возникнуть ситуации, которые не могут быть эффективно подтверждены',
    underpaid_orders: 'Сумма недоплаты',
    overpayment_orders: 'Сумма переплаты',
    underpaid_orders_tip:
      'Система обнаруживает недоплату, и сумма пополнения счета зависит от конкретной суммы счета!',
    overpayment_orders_tip:
      'Система обнаруживает переплату, и сумма пополнения счета зависит от конкретной суммы счета!',
    refund: '退款金额',
    go_back_merchant: 'Вернуться к продавцу',
    return: 'Возвращаться',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Ваш платеж подтверждается',
    pay_confirmed: 'Оплата подтверждается',
    trade_complete: 'Транзакция завершена',
    what_happened: 'Как дела?',
    trad_send_tip:
      'Ваша транзакция транслировалась, но подтверждения еще нет. DPay не имеет ваших денег, и мы не можем контролировать, когда транзакция подтверждается в Интернете. Плата за майнер, которую вы платите, определяет скорость подтверждения, и транзакция может подтвердить ваш платеж в сети в течение обычного времени от нескольких минут до нескольких часов.',
    trad_success_tip:
      'Совет: платеж подтвержден, заказ выполнен, и продавец обработает транзакцию в кратчайшие сроки.',
    check_order: 'Проверить заказ',
    order_amount_insufficient: 'Недостаточная сумма заказа',
    receiving_refund: '收取退款',
    order_over_payment: 'Переплата заказа',
    pay_tip:
      'Совет: платеж подтвержден, заказ выполнен, и продавец обработает транзакцию в кратчайшие сроки. Сумма пополнения счета зависит от конкретной суммы счета.',
  },
  // 复制
  copy: {
    copied: 'Скопировано',
    copied2: 'Платежный адрес скопирован',
    order_no: 'Номер заказа скопирован.',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'Пожалуйста, выберите одну из следующих валют для завершения платежа',
  },
  country: {
    ar: 'Арабский',
    de: 'Немецкий',
    en: 'Английский',
    es: 'Испанский',
    fr: 'Французский язык',
    hi: 'хинди',
    id: 'Индонезийский',
    it: 'Итальянский',
    ja: 'Японский язык',
    ko: 'Корейский',
    pt: 'Португальский',
    ru: 'Русский',
    th: 'Тайский',
    tr: 'Турецкий',
    vi: 'Вьетнамский',
    zh_cn: 'Китайский язык',
    zh_hk: 'Традиционный китайский',
  },
  errCode: {
    1000000: 'Пользователь/торговец ID не может быть пустым',
    1000001: 'Пользователь/торговец API-key не может быть пустым',
    1000002: 'Единственный номер заказа платформы пользователя/торговца не может быть пустым',
    1000003: 'Пользователь/торговец не существует, проверьте ID или API-key',
    1000004: 'Заказ уже существует, пожалуйста, проверьте номер заказа',
    1000005: 'Заказ не существует, пожалуйста, проверьте номер заказа',
    1000006: 'Пожалуйста, проверьте параметры конфигурации',
    1000007: 'Сумма платежа не может быть пустой',
    1000008: 'Сумма платежа не может быть меньше 0',
    1000009: 'Номер валюты не может быть пустым',
    1000010: 'Нет адреса для присвоения, пожалуйста, свяжитесь с администратором',
    1000011: 'Ошибка формата электронной почты',
  },
  header_menu: {
    logout: '退出',
  },
};
