export default {
  // 联系邮箱
  contactEmail: {
    contact_email: '이메일 연락처',
    provide_email: '결제 알림을 받으려면 이메일 주소를 입력하세요',
    email_place_holder: '편지함을 입력하십시오',
    email_address: '이메일 주소',
    tip: '이메일 주소는 결제 알림 및 주문 처리를 수신할 수 있는 유일한 방법입니다. 신중하게 작성하십시오',
    submit: '제출',
    receiveNotify: '알림을 받다',
  },
  // 支付页面
  pay: {
    confirm: 'OK',
    cancel: '취소',
    tip: '코드를 스캔하거나 지불 세부 정보를 복사하여 지갑에 붙여넣습니다',
    pay_complete_date_tip: '정해진 시간 안에 결제해주세요',
    wait_for_paying: '결제 대기 중',
    order_expire_tip:
      '이 주문은 곧 만료됩니다. 결제를 완료하려면 남은 시간 내에 결제를 보내주십시오.',
    noticed: '이해했다',
    // 付款详情
    closing_before_pay: '지정된 시간 내에 지불',
    payment_address: '지불 주소:',
    order_no: '주문 번호',
    product_name: '상품명',
    total_amount: '총 가격',
    exchange_rate: '환율',
    subtotal: '소계',
    miner_fee: '이 주문에 대해 지불할 광부 수수료',
    network_cost: '네트워크 비용',
    payable_amount: '지불 금액',
    // 订单超时
    merchant_order_number: '판매자 주문 번호',
    order_serial_number: '주문 일련 번호',
    order_expired: '주문이 만료되었습니다',
    order_expired_tip:
      '주문은 15분 동안만 유효합니다. 환불을 원하시면 판매자 페이지로 돌아가서 환불해 주시기 바랍니다.',
    try_again: '다시 시도하십시오',
    recharge_specifications: '충전 규격',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: '결제 성공',
    confirmed: '확인됨',
    exchangeRate: '환율',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: '지불 시간',
    time_of_confirming: '확인 시간',
    payment_amount: '금액 지급',
    pay_success_tip:
      '팁: 결제가 성공한 후 블록의 최종 확인을 기다려야 합니다. 확인 시간은 유료 채굴 수수료 및 네트워크 변동과 관련이 있습니다. 마이너 수수료가 너무 적게 지불되면 효과적으로 확인되지 않는 상황이 될 수 있습니다',
    underpaid_orders: '과소 지불 금액',
    overpayment_orders: '초과 지불 금액',
    underpaid_orders_tip:
      '시스템이 과소 지불을 감지하고 계정에 대한 충전 금액은 계정의 특정 금액에 따라 달라집니다!',
    overpayment_orders_tip:
      '시스템이 초과 지불을 감지하고 계정에 대한 충전 금액은 계정의 특정 금액에 따라 달라집니다!',
    refund: '退款金额',
    go_back_merchant: '판매자에게 돌아가기',
    return: '반품',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: '결제가 확인 중입니다.',
    pay_confirmed: '결제 확인 중',
    trade_complete: '거래 완료',
    what_happened: '이것은 어떻게 진행되고 있습니까?',
    trad_send_tip:
      '귀하의 거래가 방송되었지만 아직 확인되지 않았습니다. DPay에는 귀하의 돈이 없으며 인터넷에서 거래가 확인되는 시점을 제어할 수 없습니다. 당신이 지불하는 광부 수수료는 확인의 속도를 결정하고 거래는 몇 분에서 몇 시간의 정상적인 시간 내에 네트워크에서 지불을 확인할 수 있습니다.',
    trad_success_tip:
      '팁: 결제가 확인되었고 주문이 완료되었으며 판매자가 최대한 빨리 거래를 처리합니다. 계정에 대한 충전 금액은 계정의 특정 금액에 따라 다릅니다.',
    check_order: '주문 확인',
    order_amount_insufficient: '주문량 부족',
    receiving_refund: '收取退款',
    order_over_payment: '주문 초과 지불',
    pay_tip:
      '팁: 결제가 확인되었고 주문이 완료되었으며 판매자가 최대한 빨리 거래를 처리합니다. 계정에 대한 충전 금액은 계정의 특정 금액에 따라 다릅니다.',
  },
  // 复制
  copy: {
    copied: '복사됨',
    copied2: '수취인 주소가 복사되었습니다',
    order_no: '주문 번호가 복사되었습니다',
  },
  // 币种
  currency: {
    choose_currency_tip:
      '결제를 완료하려면 다음 통화 옵션 중 하나를 선택하세요',
  },
  country: {
    ar: '아라비아 말',
    de: '독일 사람',
    en: '영어',
    es: '스페인의',
    fr: '프랑스 국민',
    hi: '힌디어',
    id: '인도네시아 인',
    it: '이탈리아 사람',
    ja: '일본어',
    ko: '한국어',
    pt: '포르투갈 인',
    ru: '러시아인',
    th: '태국어',
    tr: '터키어',
    vi: '베트남어',
    zh_cn: '중국말',
    zh_hk: '중국어 번체',
  },
  errCode: {
    1000000: '사용자/판매자 ID은(는) 비워둘 수 없습니다',
    1000001: '사용자/판매자 API-key은(는) 비워둘 수 없습니다',
    1000002: '사용자/판매자 플랫폼의 유일한 주문 번호는 비워둘 수 없습니다',
    1000003: '사용자/판매자가 존재하지 않습니다. ID 또는 API-key 확인하세요',
    1000004: '주문이 이미 있습니다. 주문 번호를 확인하세요',
    1000005: '주문이 존재하지 않습니다. 주문 번호를 확인하십시오',
    1000006: '구성 매개변수를 확인하십시오',
    1000007: '결제 금액은 비워둘 수 없습니다',
    1000008: '지불 금액은 0보다 작을 수 없습니다',
    1000009: '통화의 종류 번호는 비워둘 수 없습니다',
    1000010: '할당할 주소가 없습니다. 관리자에게 문의하세요',
    1000011: '이메일 형식 오류',
  },
  header_menu: {
    logout: '退出',
  },
};
