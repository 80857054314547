export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'ई - मेल से संपर्क करे',
    provide_email:
      'भुगतान सूचना प्राप्त करने के लिए कृपया अपना ईमेल पता प्रदान करें',
    email_place_holder: 'अपना ईमेल पता दर्ज करें',
    email_address: 'ईमेल पता',
    tip: 'भुगतान सूचनाएं और आदेश प्रसंस्करण प्राप्त करने के लिए ईमेल पता ही एकमात्र तरीका होगा, कृपया ध्यान से भरें',
    submit: 'प्रस्तुत करना',
    receiveNotify: 'सूचनाएं प्राप्त करें',
  },
  // 支付页面
  pay: {
    confirm: 'निर्धारित',
    cancel: 'रद्द करना',
    tip: 'अपना भुगतान पूरा करने के लिए कृपया निम्नलिखित मुद्रा विकल्पों में से एक का चयन करें',
    pay_complete_date_tip: 'कृपया निर्दिष्ट समय के भीतर भुगतान करें',
    wait_for_paying: 'भुगतान की प्रतीक्षा करना',
    order_expire_tip:
      'यह आदेश समाप्त होने वाला है, कृपया भुगतान पूरा करने के लिए शेष समय के भीतर अपना भुगतान भेजें।',
    noticed: 'समझा',
    // 付款详情
    closing_before_pay: 'निर्दिष्ट समय के भीतर भुगतान',
    payment_address: 'भुगतान पता',
    order_no: 'आदेश संख्या',
    product_name: 'वस्त्र नाम',
    total_amount: 'कुल कीमत',
    exchange_rate: 'विनिमय दर',
    subtotal: 'उप-योग',
    miner_fee: 'इस आदेश के लिए भुगतान की जाने वाली खनिक की फीस',
    network_cost: 'नेटवर्क लागत',
    payable_amount: 'देय राशि',
    // 订单超时
    merchant_order_number: 'व्यापारी आदेश संख्या',
    order_serial_number: 'आदेश क्रमांक',
    order_expired: 'आदेश समाप्त हो गया है',
    order_expired_tip:
      'आदेश केवल 15 मिनट के लिए वैध है। यदि आप भुगतान करना चाहते हैं, तो कृपया भुगतान करने के लिए व्यापारी पृष्ठ पर वापस आएं।',
    try_again: 'पुनः प्रयास करें',
    recharge_specifications: 'रिचार्ज विनिर्देश',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'सफल भुगतान',
    confirmed: 'की पुष्टि की',
    exchangeRate: 'विनिमय दर',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'भुगतान समय',
    time_of_confirming: 'पुष्टि समय',
    payment_amount: 'भुगतान की गई राशि',
    pay_success_tip:
      'युक्ति: भुगतान सफल होने के बाद, आपको ब्लॉक की अंतिम पुष्टि के लिए प्रतीक्षा करने की आवश्यकता है। पुष्टिकरण समय भुगतान किए गए खनिक शुल्क और नेटवर्क में उतार-चढ़ाव से संबंधित है। यदि माइनर शुल्क का भुगतान बहुत कम किया जाता है, तो यह ऐसी स्थितियाँ हो सकती हैं जिनकी प्रभावी रूप से पुष्टि नहीं की जा सकती है।',
    underpaid_orders: 'कम भुगतान की गई राशि',
    overpayment_orders: 'अधिक भुगतान राशि',
    underpaid_orders_tip:
      'सिस्टम कम भुगतान का पता लगाता है, और खाते में पुनर्भरण की राशि खाते की विशिष्ट राशि के अधीन होती है!',
    overpayment_orders_tip:
      'सिस्टम अधिक भुगतान का पता लगाता है, और खाते में पुनर्भरण की राशि खाते की विशिष्ट राशि के अधीन होती है!',
    refund: '退款金额',
    go_back_merchant: 'व्यापारी को वापसी',
    return: 'वापस करना',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'आपके भुगतान की पुष्टि की जा रही है',
    pay_confirmed: 'भुगतान की पुष्टि हो रही है',
    trade_complete: 'लेन-देन पूर्ण',
    what_happened: 'यह कैसा चल रहा है?',
    trad_send_tip:
      'आपका लेन-देन प्रसारित कर दिया गया है, लेकिन अभी तक कोई पुष्टि नहीं हुई है। DPay के पास आपका पैसा नहीं है, और इंटरनेट पर लेन-देन की पुष्टि होने पर हम नियंत्रित नहीं कर सकते। आपके द्वारा भुगतान किया जाने वाला माइनर शुल्क पुष्टि की गति निर्धारित करता है, और लेन-देन कुछ मिनटों से लेकर कुछ घंटों के सामान्य समय के भीतर नेटवर्क पर आपके भुगतान की पुष्टि कर सकता है।',
    trad_success_tip:
      'युक्ति: भुगतान की पुष्टि हो गई है, आदेश पूरा हो गया है, और व्यापारी लेनदेन को जल्द से जल्द संसाधित करेगा।',
    check_order: 'आदेश की जाँच करें',
    order_amount_insufficient: 'अपर्याप्त आदेश राशि',
    receiving_refund: '收取退款',
    order_over_payment: 'आदेश का अधिक भुगतान',
    pay_tip:
      'युक्ति: भुगतान की पुष्टि हो गई है, आदेश पूरा हो गया है, और व्यापारी लेनदेन को जल्द से जल्द संसाधित करेगा। खाते में रिचार्ज की राशि खाते की विशिष्ट राशि के अधीन है।',
  },
  // 复制
  copy: {
    copied: 'सफल प्रति',
    copied2: 'भुगतान पता कॉपी कर लिया गया है',
    order_no: 'ऑर्डर नंबर कॉपी हो गया है',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'अपना भुगतान पूरा करने के लिए कृपया निम्नलिखित मुद्रा विकल्पों में से एक का चयन करें',
  },
  country: {
    ar: 'अरबी',
    de: 'जर्मन',
    en: 'अंग्रेज़ी',
    es: 'स्पेनिश',
    fr: 'फ्रेंच',
    hi: 'हिंदी',
    id: 'इन्डोनेशियाई',
    it: 'इतालवी',
    ja: 'जापानी',
    ko: 'कोरियाई',
    pt: 'पुर्तगाली',
    ru: 'रूसी',
    th: 'थाई',
    tr: 'तुर्की',
    vi: 'वियतनामी',
    zh_cn: 'चीनी',
    zh_hk: 'परंपरागत चीनी',
  },
  errCode: {
    1000000: 'उपयोगकर्ता/व्यापारी ID खाली नहीं हो सकता',
    1000001: 'उपयोगकर्ता/व्यापारी API-key खाली नहीं हो सकता',
    1000002: 'उपयोगकर्ता/व्यापारी प्लेटफॉर्म का एकमात्र ऑर्डर नंबर खाली नहीं हो सकता',
    1000003: 'उपयोगकर्ता/व्यापारी मौजूद नहीं है, कृपया आईडी या API-key जांचें',
    1000004: 'आदेश पहले से मौजूद है, कृपया आदेश संख्या जांचें',
    1000005: 'आदेश मौजूद नहीं है, कृपया आदेश संख्या जांचें',
    1000006: 'कृपया कॉन्फ़िगरेशन पैरामीटर जांचें',
    1000007: 'भुगतान राशि खाली नहीं हो सकती',
    1000008: 'भुगतान राशि 0 से कम नहीं हो सकती',
    1000009: 'मुद्रा संख्या खाली नहीं हो सकती',
    1000010: 'असाइन करने के लिए कोई पता नहीं है, कृपया व्यवस्थापक से संपर्क करें',
    1000011: 'ईमेल प्रारूप त्रुटि',
  },
  header_menu: {
    logout: '退出',
  },
};
