export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'Email du contact',
    provide_email:
      'Veuillez fournir votre adresse e-mail pour recevoir une notification de paiement',
    email_place_holder: 'Entrez votre boîte aux lettres',
    email_address: 'Adresse e-mail',
    tip: "L'adresse e-mail sera le seul moyen pour vous de recevoir les notifications de paiement et le traitement des commandes, veuillez la remplir soigneusement",
    submit: 'Soumettre',
    receiveNotify: 'recevoir des notifications',
  },
  // 支付页面
  pay: {
    confirm: 'Oui',
    cancel: 'Annuler',
    tip: "Veuillez sélectionner l'une des options de devise suivantes pour effectuer votre paiement",
    pay_complete_date_tip: 'Veuillez payer dans le délai spécifié',
    wait_for_paying: 'En attente de paiement',
    order_expire_tip:
      "Cette commande est sur le point d'expirer, veuillez envoyer votre paiement dans le délai restant pour effectuer le paiement.",
    noticed: 'Entendu',
    // 付款详情
    closing_before_pay: 'Paiement dans le délai indiqué',
    payment_address: 'Adresse de paiement',
    order_no: 'Numéro de commande',
    product_name: 'Nom du produit',
    total_amount: 'Prix ​​total',
    exchange_rate: 'Taux de change',
    subtotal: 'Total',
    miner_fee: 'Les frais de mineur à payer pour cette commande',
    network_cost: 'Coût du réseau',
    payable_amount: 'Montants à payer',
    // 订单超时
    merchant_order_number: 'Numéro de commande marchand',
    order_serial_number: 'Numéro de série de la commande',
    order_expired: 'La commande a expiré',
    order_expired_tip:
      "La commande n'est valable que 15 minutes. Si vous souhaitez rembourser, veuillez retourner sur la page marchand pour rembourser.",
    try_again: 'Réessayer',
    recharge_specifications: 'Spécifications de recharge',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Paiement réussi',
    confirmed: 'Confirmé',
    exchangeRate: 'Taux de change',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Délai de paiement',
    time_of_confirming: 'Heure de confirmation',
    payment_amount: 'Le montant payé',
    pay_success_tip:
      "Astuce : Une fois le paiement réussi, vous devez attendre la confirmation finale du blocage. Le temps de confirmation est lié aux frais de mineur payés et aux fluctuations du réseau. Si les frais de mineur sont payés trop bas, il peut s'agir de situations qui ne peuvent pas être effectivement confirmées",
    underpaid_orders: 'Montant sous-payé',
    overpayment_orders: 'Montant payé en trop',
    underpaid_orders_tip:
      'Le système détecte le sous-paiement et le montant de la recharge sur le compte est soumis au montant spécifique du compte !',
    overpayment_orders_tip:
      'Le système détecte le trop-payé, et le montant de la recharge sur le compte est soumis au montant spécifique du compte !',
    refund: '退款金额',
    go_back_merchant: 'Retourner au marchand',
    return: 'Retour',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Votre paiement est en cours de confirmation',
    pay_confirmed: 'Le paiement confirme',
    trade_complete: 'Transaction terminée',
    what_happened: 'Comment ça se passe ?',
    trad_send_tip:
      "Votre transaction a été diffusée, mais il n'y a pas encore de confirmation. DPay n'a pas votre argent et nous ne pouvons pas contrôler le moment où la transaction est confirmée sur Internet. Les frais de mineur que vous payez déterminent la vitesse de confirmation, et la transaction peut confirmer votre paiement sur le réseau dans un délai normal de quelques minutes à quelques heures.",
    trad_success_tip:
      'Astuce : Le paiement a été confirmé, la commande a été finalisée et le commerçant traitera la transaction dans les plus brefs délais.',
    check_order: 'Vérifier la commande',
    order_amount_insufficient: 'Montant de la commande insuffisant',
    receiving_refund: '收取退款',
    order_over_payment: 'Trop-payé de la commande',
    pay_tip:
      'Astuce : Le paiement a été confirmé, la commande a été finalisée et le commerçant traitera la transaction dans les plus brefs délais. Le montant de la recharge sur le compte est soumis au montant spécifique du compte.',
  },
  // 复制
  copy: {
    copied: 'Copié',
    copied2: `Adresse de paiement copiée`,
    order_no: 'Numéro de commande copié',
  },
  // 币种
  currency: {
    choose_currency_tip:
      "Veuillez sélectionner l'une des options de devise suivantes pour effectuer votre paiement",
  },
  country: {
    ar: 'Arabe',
    de: 'Allemand',
    en: 'Anglais',
    es: 'Espagnol',
    fr: 'Français',
    hi: 'hindi',
    id: 'Indonésien',
    it: 'Italien',
    ja: 'Japonais',
    ko: 'Coréen',
    pt: 'Portugais',
    ru: 'Russe',
    th: 'Thaïlandais',
    tr: 'Turc',
    vi: 'Vietnamien',
    zh_cn: 'Chinois',
    zh_hk: 'Chinois traditionnel',
  },
  errCode: {
    1000000: "L'utilisateur/commerçant ID ne peut pas être vide",
    1000001: "L'utilisateur/commerçant API-key ne peut pas être vide",
    1000002: 'Le seul numéro de commande de la plateforme utilisateur/marchand ne peut pas être vide',
    1000003: "L'utilisateur/commerçant n'existe pas, veuillez vérifier l'ID ou la  API-key",
    1000004: 'La commande existe déjà, veuillez vérifier le numéro de commande',
    1000005: "La commande n'existe pas, veuillez vérifier le numéro de commande",
    1000006: 'Veuillez vérifier les paramètres de configuration',
    1000007: 'Le montant du paiement ne peut pas être vide',
    1000008: 'Le montant du paiement ne peut pas être inférieur à 0',
    1000009: 'Le numéro de devise ne peut pas être vide',
    1000010: "Il n'y a pas d'adresse à attribuer, merci de contacter l'administrateur",
    1000011: "Erreur de format d'e-mail",
  },
  header_menu: {
    logout: '退出',
  },
};
