/**
 *
 *获取 assets 下文件路径
 * @param {string} folder 文件名
 * @param {string} key 文件名称
 * @return {*}
 */
const getFilePath = (folder: string = 'imgs', key: string) => {
  // const files = import.meta.globEager(`./../assets/${folder}/*`)
  let files: any = '';
  let type: string = '';
  // 目前不支持变量就很难受
  switch (folder) {
    case 'imgs':
      files = import.meta.globEager(`./../assets/imgs/*`);
      type = 'png';
      break;
    default:
      break;
  }
  if (files && key) {
    return files[`./../assets/${folder}/${key}.${type}`];
  }
  return files;
};

/**
 * 直接截取
 * @param {string} data 保留的数值
 * @param {string} num 保留位数 默认6位
 * @return {*}
 *
 */
// import { computed } from 'vue';
const toFixed = (data: number | null | undefined | string, num: number = 6) => {
  if(typeof data == 'string') data = Number(data)
  if (typeof data == 'number') {
    return data.toFixed(num + 1).slice(0, -1);
  }
  return data;
};

export const utils = {
  getFilePath,
  toFixed,
};
