import Vue from 'vue';
import { createI18n } from 'vue-i18n';
import { Locale } from 'vant';
import enUS from 'vant/lib/locale/lang/en-US';
import zhCN from 'vant/lib/locale/lang/zh-CN';

const modules: any = import.meta.globEager('./lang/*.ts');

const localArr = [];
const getLangAll = () => {
  const messages = {};
  const errorCode = {};
  const langKeyOptions = [];
  Object.entries(modules).forEach(([k, v]: Array<any>) => {
    const key = k.slice(k.lastIndexOf('/') + 1, -3);
    messages[key] = v.default;
    errorCode[key] = v.default.errCode;
    localArr.push(key);
    langKeyOptions.push({
      value: key,
      text: key,
    });
  });
  sessionStorage.setItem('errorCode', JSON.stringify(errorCode));
  return {
    langKeyOptions,
    messages,
  };
};
const langInfo = getLangAll();

export const langKeyOptions = langInfo.langKeyOptions;

export const vantLocales = (lang: string) => {
  if (lang === 'en') {
    Locale.use(lang, enUS);
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN);
  }
};

let locale = JSON.parse(sessionStorage.getItem('language'));
if (!locale) {
  const language = navigator.language
    ? navigator.language
    : navigator['browserLanguage'] || navigator['userLanguage'] || 'en';
  const languageLow = language.toLocaleLowerCase();
  if (languageLow.indexOf('zh') > -1) {
    locale = languageLow.indexOf('cn') > -1 ? 'zh_cn' : 'zh_hk';
  } else {
    const l = languageLow.slice(0, 2);
    locale = localArr.find((v) => v.indexOf(l) > -1);
  }

  sessionStorage.setItem('language', JSON.stringify(locale));
}

export const i18n = createI18n({
  locale, // 默认显示语音
  legacy: false,
  messages: langInfo.messages,
});
