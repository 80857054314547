export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'Địa chỉ email liên lạc',
    provide_email:
      'Vui lòng cung cấp địa chỉ email của bạn để nhận thông báo thanh toán',
    email_place_holder: 'nhập email của bạn',
    email_address: 'Địa chỉ email',
    tip: 'Địa chỉ email sẽ là cách duy nhất để bạn nhận thông báo thanh toán và xử lý đơn hàng, vui lòng điền cẩn thận',
    submit: 'đệ trình',
    receiveNotify: 'nhận thông báo',
  },
  // 支付页面
  pay: {
    confirm: 'xác định',
    cancel: 'hủy',
    tip: 'Quét mã hoặc sao chép và dán chi tiết thanh toán vào ví của bạn',
    pay_complete_date_tip: 'Vui lòng thanh toán trong thời gian quy định',
    wait_for_paying: 'Đang chờ thanh toán',
    order_expire_tip:
      'Đơn hàng này sắp hết hạn, vui lòng gửi thanh toán của bạn trong thời gian còn lại để hoàn tất việc thanh toán.',
    noticed: 'Hiểu',
    // 付款详情
    closing_before_pay: 'Thanh toán trong thời gian quy định',
    payment_address: 'Địa chỉ thanh toán',
    order_no: 'Mã đơn hàng',
    product_name: 'Tên sản phẩm',
    total_amount: 'Tổng giá',
    exchange_rate: 'Tỷ giá',
    subtotal: 'Tổng phụ',
    miner_fee: 'Phí người khai thác phải trả cho đơn đặt hàng này',
    network_cost: 'Chi phí mạng',
    payable_amount: 'Số tiền được thanh toán',
    // 订单超时
    merchant_order_number: 'Số đơn đặt hàng của người bán',
    order_serial_number: 'Số sê-ri đặt hàng',
    order_expired: 'Đơn hàng đã hết hạn',
    order_expired_tip:
      'Lệnh chỉ có hiệu lực trong vòng 15 phút. Nếu bạn muốn hoàn trả, vui lòng quay lại trang thương gia để hoàn trả.',
    try_again: 'Thử lại',
    recharge_specifications: 'Thông số kỹ thuật nạp tiền',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Thanh toán thành công',
    confirmed: 'Đã xác nhận',
    exchangeRate: 'Tỷ giá',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Thời gian thanh toán',
    time_of_confirming: 'Thời gian xác nhận',
    payment_amount: 'Số tiền đã trả',
    pay_success_tip:
      'Mẹo: Sau khi thanh toán thành công, bạn cần đợi xác nhận cuối cùng của khối. Thời gian xác nhận có liên quan đến phí khai thác đã trả và biến động mạng. Nếu phí người khai thác được trả quá thấp, đó có thể là những tình huống không thể xác nhận hiệu quả',
    underpaid_orders: 'Số tiền chưa thanh toán',
    overpayment_orders: 'Số tiền trả thừa',
    underpaid_orders_tip:
      'Hệ thống phát hiện thanh toán thiếu và số tiền nạp vào tài khoản tùy thuộc vào số tiền cụ thể của tài khoản!',
    overpayment_orders_tip:
      'Hệ thống phát hiện nạp quá, và số tiền nạp vào tài khoản phụ thuộc vào số tiền cụ thể của tài khoản!',
    refund: '退款金额',
    go_back_merchant: 'Trả lại cho người bán',
    return: 'Trở lại',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Thanh toán của bạn đang được xác nhận',
    pay_confirmed: 'Thanh toán đang xác nhận',
    trade_complete: 'Giao dịch hoàn thành',
    what_happened: 'Chuyện này diễn ra như thế nào?',
    trad_send_tip:
      'Giao dịch của bạn đã được phát đi, nhưng vẫn chưa có xác nhận. DPay không có tiền của bạn và chúng tôi không thể kiểm soát thời điểm giao dịch được xác nhận trên Internet. Phí người khai thác bạn trả xác định tốc độ xác nhận và giao dịch có thể xác nhận thanh toán của bạn trên mạng trong thời gian bình thường từ vài phút đến vài giờ.',
    trad_success_tip:
      'Mẹo: Thanh toán đã được xác nhận, đơn đặt hàng đã hoàn tất và người bán sẽ xử lý giao dịch sớm nhất có thể.',
    check_order: 'Kiểm tra đơn hàng',
    order_amount_insufficient: 'Không đủ số lượng đặt hàng',
    receiving_refund: '收取退款',
    order_over_payment: 'Thanh toán quá nhiều đơn đặt hàng',
    pay_tip:
      'Mẹo: Thanh toán đã được xác nhận, đơn đặt hàng đã hoàn tất và người bán sẽ xử lý giao dịch sớm nhất có thể. Số tiền nạp vào tài khoản tùy thuộc vào số tiền cụ thể của tài khoản.',
  },
  // 复制
  copy: {
    copied: 'Đã sao chép',
    copied2: 'Đã sao chép địa chỉ thanh toán',
    order_no: 'Đã sao chép số đơn hàng',
  },
  // 币种
  currency: {
    choose_currency_tip:
      'Vui lòng chọn một trong các tùy chọn đơn vị tiền tệ sau để hoàn tất thanh toán của bạn',
  },
  country: {
    ar: 'Tiếng ả rập',
    de: 'Tiếng đức',
    en: 'Tiếng anh',
    es: 'Người tây ban nha',
    fr: 'Người pháp',
    hi: 'Hindi',
    id: 'Người indonesia',
    it: 'Người ý',
    ja: 'Tiếng nhật',
    ko: 'Hàn quốc',
    pt: 'Người bồ đào nha',
    ru: 'Tiếng nga',
    th: 'Thái',
    tr: 'Thổ nhĩ kỳ',
    vi: 'Tiếng việt',
    zh_cn: 'Người trung quốc',
    zh_hk: 'Truyền thống trung quốc',
  },
  errCode: {
    1000000: 'Người dùng / người bán ID không được để trống',
    1000001: 'Người dùng / người bán API-key không được để trống',
    1000002: 'Số đơn đặt hàng duy nhất của nền tảng người dùng/người bán không được để trống',
    1000003: 'Người dùng / người bán không tồn tại, vui lòng kiểm tra ID hoặc API-key',
    1000004: 'Đơn hàng đã tồn tại, vui lòng kiểm tra số đơn hàng',
    1000005: 'Đơn đặt hàng không tồn tại, vui lòng kiểm tra số đơn đặt hàng',
    1000006: 'Vui lòng kiểm tra các thông số cấu hình',
    1000007: 'Số tiền thanh toán không được để trống',
    1000008: 'Số tiền thanh toán không được nhỏ hơn 0',
    1000009: 'Số tiền tệ không được để trống',
    1000010: 'Không có địa chỉ để chỉ định, vui lòng liên hệ với quản trị viên',
    1000011: 'Lỗi định dạng email',
  },
  header_menu: {
    logout: '退出',
  },
};
