<template>
  <div :class="[languageType]">
    <nav-top v-if="!isHideNav"></nav-top>
    <switch-language v-show="!isHideNav"></switch-language>
    <router-view></router-view>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import NavTop from './components/nav.vue';
import SwitchLanguage from './components/switchLanguage.vue';

import { useStore } from 'vuex';


const store = useStore();
const languageType = computed(() => store.state.languageType);

const router = useRouter();

const isHideNav = computed(() => {
  const { path } = router.currentRoute.value;

  // 加上 / 是为了防止路由重定向顶部出现闪动
  return path == '/payAccountEmail' || path == '/';
});

</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.3rem;
  //padding-top: 0.34rem;
  background: #F5F5F5;
  height: 100%;
  min-height: 100vh;
  width: 7.5rem;
  margin: 0 auto;
  padding-bottom: 0.4rem;
  /* height: 100vh; */
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}

@media screen and (min-width: 540px) {
  html {
    zoom: 72%;
  }
}

.van-popover--light .van-popover__arrow {
  color: #00D8B1 !important;
  bottom: 0.02rem !important;
}

.van-overlay {
  width: 7.5rem !important;
  left: 50% !important;
  transform: translate(-50%);
}

.popoverText {
  font-size: 0.21rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.29rem;
  background: #00D8B1;
  padding: 0.14rem 0.16rem;
  max-width: 3.5rem;
  width: fit-content;
}

.dsf {
  display: flex;
}

.jcc {
  justify-content: center;
}

.jcsa {
  justify-content: space-around;
}

.jcsb {
  justify-content: space-between;
}

.fdc {
  flex-direction: column;
}

.aic {
  align-items: center;
}

.tac {
  text-align: center;
}

.footer {
  margin-top: 1rem;
}

.footer .email-logo {
  width: 0.64rem;
  height: 0.64rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.ar .van-field__control {
  text-align: right !important;
}
</style>

