export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'تتصل بالبريد الاكتروني',
    provide_email: 'يرجى تقديم عنوان بريدك الإلكتروني لتستقبل إعلان الدفع',
    email_place_holder: 'أدخل البريد الالكتروني الخاص بك ',
    email_address: 'الرجاء تحديد حالة العاطفة ',
    tip: 'سيكون عنوان البريد الإلكتروني هوالطريقة الوحيدة التي ستتلقى فيها إشعارات الدفع ومعالجة ,الطلب.، يرجى ملء بعناية',
    submit: 'تقديم',
    receiveNotify: 'استلام الإشعارات',
  },
  // 支付页面
  pay: {
    confirm: 'موافق',
    cancel: 'إلغاء',
    tip: 'امسح الرمز أو انسخ تفاصيل الدفع والصقها في محفظتك',
    pay_complete_date_tip: 'يرجى الدفع في الوقت المحدد',
    wait_for_paying: 'في انتظار الدفع',
    order_expire_tip:
      'هذا الطلب على وشك الانتهاء ، يرجى إرسال دفعتك في غضون الوقت المتبقي لإتمام الدفع.',
    noticed: 'عرفت',
    // 付款详情
    closing_before_pay: 'الدفع خلال الوقت المحدد',
    payment_address: 'عنوان الدفع',
    order_no: 'رقم الطلب',
    product_name: 'اسم المنتج',
    total_amount: 'السعر الكلي',
    exchange_rate: 'سعر الصرف',
    subtotal: 'المجموع الفرعي',
    miner_fee: 'يدفع هذا الطلب علي رسوم عامل التعدين',
    network_cost: 'تكلفة الشبكة',
    payable_amount: 'المبالغ المستحقة لدفع',
    // 订单超时
    merchant_order_number: 'رقم طلب التاجر',
    order_serial_number: 'الرقم التسلسلي للطلب',
    order_expired: 'انتهى الطلب',
    order_expired_tip:
      'الطلب صالح لمدة 15 دقيقة فقط ، إذا كنت ترغب في الدفع ، يرجى العودة إلى صفحة التاجر لاكمال الدفع.',
    try_again: 'حاول مرة أخري',
    recharge_specifications: 'مواصفات إعادة الشحن',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'تم الدفع بنجاح',
    confirmed: 'تم التوكيد',
    exchangeRate: 'سعر الصرف',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'وقت الدفع',
    time_of_confirming: 'تأكيد الوقت',
    payment_amount: 'المبالغ المدفوعة',
    pay_success_tip:
      'نصيحة: بعد نجاح الدفع ، عليك انتظار التأكيد النهائي للحظر. يرتبط وقت التأكيد برسوم عامل المنجم المدفوع وتقلبات الشبكة. إذا تم دفع رسوم المنجم منخفضة جدًا ، فقد لا يتم تأكيدها بشكل صحيح.',
    underpaid_orders: 'الدفع الناقص',
    overpayment_orders: 'دفع مبالغ زائدة',
    underpaid_orders_tip:
      'النظام يكتشف الدفع الناقص ، ويخضع مبلغ إعادة الشحن إلى مبلغ الحساب المحدد!',
    overpayment_orders_tip:
      'النظام يكتشف المدفوعات الزائدة ، ويخضع مبلغ إعادة الشحن إلى مبلغ الحساب المحدد!',
    refund: '退款金额',
    go_back_merchant: 'العودة إلى التاجر',
    return: 'إرجاع',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  pay_account: {
    pay_confirmed_tip: 'دفعك تحت التحقيق',
    pay_confirmed: 'لايزال التحقيق حاليا',
    trade_complete: 'تمت الصفقة',
    what_happened: 'ما هذا؟',
    trad_send_tip:
      'تم بث معاملتك ، لكن لا يوجد تأكيد حتى الآن. لا تملك DPay أموالك ، ولا يمكننا التحكم في وقت تأكيد المعاملة على الإنترنت. تحدد رسوم عامل المناجم التي تدفعها سرعة التأكيد ، وقد تؤكد المعاملة دفعك على الشبكة في غضون الوقت العادي من بضع دقائق إلى بضع ساعات.',
    trad_success_tip:
      'تذكير: تم تأكيد الدفع ، واكتمل الطلب ، وسيقوم التاجر بمعالجة المعاملة في أقرب وقت ممكن.',
    check_order: 'تحقق من الطلب',
    order_amount_insufficient: 'مبلغ الطلب غير كافية',
    receiving_refund: '收取退款',
    order_over_payment: 'طلب دفع مبالغ زائدة',
    pay_tip:
      'تذكير: تم تأكيد الدفع ، واكتمل الطلب ، وسيقوم التاجر بمعالجة المعاملة في أقرب وقت ممكن. مقدار إعادة الشحن إلى الحساب يخضع لمبلغ الحساب المحدد.',
  },
  // 复制
  copy: {
    copied: 'قد دفع',
    copied2: 'تم نسخ عنوان الدفع',
    order_no: 'تم نسخ رقم الطلب',
  },
  // 币种
  currency: {
    choose_currency_tip: 'الرجاء تحديد أحد خيارات العملة التالية لإكمال الدفع',
  },
  country: {
    ar: 'فرنسي',
    de: 'ألمانية',
    en: 'الإنجليزية',
    es: 'الأسبانية',
    fr: 'فرنسا',
    hi: 'هندي',
    id: 'الأندونيسية',
    it: 'إيطالي',
    ja: 'اليابانية',
    ko: 'الكورية',
    pt: 'البرتغالية',
    ru: 'الروسية',
    th: 'التايلاندية',
    tr: 'اللغة التركية',
    vi: 'فيتنامي',
    zh_cn: 'صينى',
    zh_hk: 'الصينية التقليدية',
  },
  errCode: {
    1000000: 'لا يمكن أن يكون المستخدم / التاجر ID فارغًا',
    1000001: 'لا يمكن أن يكون المستخدم / التاجر API-key فارغًا',
    1000002: 'لا يمكن أن يكون رقم الطلب الفريد لمنصة المستخدم / التاجر فارغًا',
    1000003: 'المستخدم / التاجر غير موجود ، يرجى التحقق من المعرف أو مفتاح API',
    1000004: 'الطلب موجود بالفعل ، يرجى التحقق من رقم الطلب',
    1000005: 'الطلب غير موجود ، يرجى التحقق من رقم الطلب',
    1000006: 'يرجى التحقق من معلمات التكوين',
    1000007: 'لا يمكن أن يكون مبلغ الدفع فارغًا',
    1000008: 'لا يمكن أن يكون مبلغ الدفعة أقل من 0',
    1000009: 'لا يمكن أن يكون رقم العملة فارغًا',
    1000010: 'لا يوجد عنوان لتوزيعه ، يرجى الاتصال بالمسؤول',
    1000011: 'خطأ في تنسيق صندوق البريد',
  },
  header_menu: {
    logout: '退出',
  },
};
