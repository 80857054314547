export default {
  // 联系邮箱
  contactEmail: {
    contact_email: 'Contatto email',
    provide_email:
      'Fornisci il tuo indirizzo email per ricevere la notifica di pagamento',
    email_place_holder: 'Inserisci la tua casella di posta',
    email_address: 'Indirizzo e-mail',
    tip: "L'indirizzo e-mail sarà l'unico modo per ricevere le notifiche di pagamento e l'elaborazione degli ordini, si prega di compilare con attenzione",
    submit: 'Inviare',
    receiveNotify: 'ricevere notifiche',
  },
  // 支付页面
  pay: {
    confirm: 'OK',
    cancel: 'annullare',
    tip: 'Seleziona una delle seguenti opzioni di valuta per completare il pagamento',
    pay_complete_date_tip: 'Si prega di pagare entro il tempo specificato',
    wait_for_paying: 'In attesa del pagamento',
    order_expire_tip:
      'Questo ordine sta per scadere, invia il pagamento entro il tempo rimanente per completare il pagamento.',
    noticed: 'Inteso',
    // 付款详情
    closing_before_pay: 'Pagamento entro il tempo specificato',
    payment_address: 'Indirizzo di pagamento',
    order_no: "Numero d'Ordine",
    product_name: 'Nome Prodotto',
    total_amount: 'Prezzo totale',
    exchange_rate: 'Tasso di cambio',
    subtotal: 'Totale parziale',
    miner_fee: 'La commissione del minatore da pagare per questo ordine',
    network_cost: 'Costo di rete',
    payable_amount: 'Importi da pagare',
    // 订单超时
    merchant_order_number: 'Numero ordine commerciante',
    order_serial_number: "Numero di serie dell'ordine",
    order_expired: "L'ordine è scaduto",
    order_expired_tip:
      "L'ordine è valido solo per 15 minuti. Se desideri rimborsare, torna alla pagina del commerciante per rimborsare.",
    try_again: 'Riprova',
    recharge_specifications: 'Specifiche di ricarica',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: 'Pagamento riuscito',
    confirmed: 'Confermato',
    exchangeRate: 'Tasso di cambio',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: 'Tempo di pagamento',
    time_of_confirming: 'Ora di conferma',
    payment_amount: 'Importo pagato',
    pay_success_tip:
      'Suggerimento: dopo che il pagamento è andato a buon fine, è necessario attendere la conferma finale del blocco. Il tempo di conferma è correlato alla commissione del minatore pagata e alle fluttuazioni della rete. Se la commissione del miner viene pagata troppo bassa, potrebbero esserci situazioni che non possono essere effettivamente confermate',
    underpaid_orders: 'Importo sottopagato',
    overpayment_orders: 'Importo pagato in eccesso',
    underpaid_orders_tip:
      "Il sistema rileva il mancato pagamento e l'importo della ricarica sul conto è soggetto all'importo specifico del conto!",
    overpayment_orders_tip:
      "Il sistema rileva il pagamento in eccesso e l'importo della ricarica sul conto è soggetto all'importo specifico del conto!",
    refund: '退款金额',
    go_back_merchant: 'Ritorna al commerciante',
    return: 'Ritorno',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: 'Il tuo pagamento è in fase di conferma',
    pay_confirmed: 'Il pagamento sta confermando',
    trade_complete: 'Transazione completata',
    what_happened: 'Come sta andando?',
    trad_send_tip:
      "La tua transazione è stata trasmessa, ma non c'è ancora conferma. DPay non ha i tuoi soldi e non possiamo controllare quando la transazione è confermata su Internet. La commissione del minatore che paghi determina la velocità di conferma e la transazione può confermare il tuo pagamento sulla rete entro un tempo normale da pochi minuti a poche ore.",
    trad_success_tip:
      "Suggerimento: il pagamento è stato confermato, l'ordine è stato completato e il commerciante elaborerà la transazione il prima possibile. L'importo della ricarica sul conto è soggetto all'importo specifico del conto.",
    check_order: "Controlla l'ordine",
    order_amount_insufficient: "Importo dell'ordine insufficiente",
    receiving_refund: '收取退款',
    order_over_payment: "Pagamento in eccesso dell'ordine",
    pay_tip:
      "Suggerimento: il pagamento è stato confermato, l'ordine è stato completato e il commerciante elaborerà la transazione il prima possibile. L'importo della ricarica sul conto è soggetto all'importo specifico del conto.",
  },
  // 复制
  copy: {
    copied: 'Copiato',
    copied2: `L'indirizzo di pagamento è stato copiato`,
    order_no: `Numero d'ordine copiato`,
  },
  // 币种
  currency: {
    choose_currency_tip:
      'Si prega di selezionare una delle seguenti opzioni di valuta per completare il pagamento',
  },
  country: {
    ar: 'Arabo',
    de: 'Tedesco',
    en: 'Inglese',
    es: 'Spagnolo',
    fr: 'Francese',
    hi: 'Hindi',
    id: 'Indonesiano',
    it: 'Italiano',
    ja: 'Giapponese',
    ko: 'Coreano',
    pt: 'Portoghese',
    ru: 'Russo',
    th: 'Tailandese',
    tr: 'Turco',
    vi: 'Vietnamita',
    zh_cn: 'Cinese',
    zh_hk: 'Cinese tradizionale',
  },
  errCode: {
    1000000: "L'utente/commerciante ID non può essere vuoto",
    1000001: "L'utente/commerciante API-key non può essere vuoto",
    1000002: "L'unico numero d'ordine della piattaforma utente/commerciante non può essere vuoto",
    1000003: "L'utente/commerciante non esiste, controlla l'ID o la API-key",
    1000004: "L'ordine esiste già, controlla il numero d'ordine",
    1000005: "L'ordine non esiste, si prega di controllare il numero d'ordine",
    1000006: 'Si prega di controllare i parametri di configurazione',
    1000007: "L'importo del pagamento non può essere vuoto",
    1000008: "L'importo del pagamento non può essere inferiore a 0",
    1000009: "Il numero di valuta non può essere vuoto",
    1000010: "Non c'è un indirizzo da assegnare, si prega di contattare l'amministratore",
    1000011: "Errore nel formato dell'e-mail",
  },
  header_menu: {
    logout: '退出',
  },
};
