export default {
  // 联系邮箱
  contactEmail: {
    contact_email: '聯繫郵箱',
    provide_email: '請提供您的電子郵箱來接收付款通知',
    email_place_holder: '輸入你的郵箱地址',
    email_address: '電子郵箱地址',
    tip: '郵箱將是您後續接收付款通知及訂單處理的唯一方式，請認真填寫',
    submit: '提交',
    receiveNotify: '接收通知',
  },
  // 支付页面
  pay: {
    confirm: '確定',
    cancel: '取消',
    tip: '掃碼或將付款詳細信息複製粘貼到您的錢包',
    pay_complete_date_tip: '請在規定時間內付款',
    wait_for_paying: '等待付款',
    order_expire_tip:
      '此訂單即將過期，請在剩餘的時間內發送您的付款以完成支付。 ',
    noticed: '知道了',
    // 付款详情
    closing_before_pay: '在所規定時間內付款：',
    payment_address: '付款地址',
    order_no: '訂單編號',
    product_name: '商品名稱',
    total_amount: '總價',
    exchange_rate: '匯率',
    subtotal: '小計',
    miner_fee: '該筆訂單需要支付的礦工費',
    network_cost: '網絡成本',
    payable_amount: '應付金額',
    // 订单超时
    merchant_order_number: '商戶訂單編號',
    order_serial_number: '訂單流水編號',
    order_expired: '訂單已過期',
    order_expired_tip:
      '訂單有效時間僅15分鐘，如果您希望重新付款，請回到商家頁面重新支付。 ',
    try_again: '再试一次',
    recharge_specifications: '儲值的規格',
  },
  // 支付结果页面
  pay_result: {
    paidSuccessfully: '付款成功',
    confirmed: '已確認',
    exchangeRate: '匯率',
    // 应付总额 网络成本 支付页面已有
    time_of_paying: '付款時間',
    time_of_confirming: '確認時間',
    payment_amount: '支付的金額',
    pay_success_tip:
      '提示：付款成功後，需等待區塊最終確認，該確認時長與支付的礦工費和網絡波動有關，支付過低礦工費可能出現始終無法有效確認的情況',
    underpaid_orders: '少付金額',
    overpayment_orders: '多付金額',
    underpaid_orders_tip: '系統檢測超額支付，充值到賬數額以具體到賬金額為準！',
    overpayment_orders_tip:
      '系統檢測超額支付，充值到賬數額以具體到賬金額為準！',
    refund: '退款金额',
    go_back_merchant: '返回商戶',
    return: '返回',
  },
  // 退款页面
  refund: {
    currency_type: '币种',
    processing_fee: '手续费',
    // currency_type:'到账金额' 缺少,
    blockchain_name: '链名称',
    address_receiving_refund: '退款地址',
    address_info_placeholder: '如钱包、交易所等地址信息',
    blockchain_trade_ID: '区块链交易ID',
    attention: '注意',
    check_address_tip:
      '请务必核对地址是否正确，否则有丢失币的风险，如果您填写或粘贴的地址错误，损失由您承担。',
    notes: '备注',
    time: '时间', // live_game_create_time
    refunded: '退款成功',
    refunding: '退款中',
  },
  // 确认
  pay_account: {
    pay_confirmed_tip: '您的付款正在確認',
    pay_confirmed: '付款確認中',
    trade_complete: '交易完成',
    what_happened: '這是怎麼回事？',
    trad_send_tip:
      '您的交易已被廣播，但尚無任何確認。 DPay沒有您的錢，我們也無法控制何時在網絡上確認交易。您支付的礦工費決定確認的快慢，交易可能在幾分鐘到幾小時的正常時間內在網絡上確認您的付款。',
    trad_success_tip:
      '提示：付款已確認，該筆訂單已完成，商戶會盡快進行交易處理。',
    check_order: '查看訂單',
    order_amount_insufficient: '訂單金額不足',
    receiving_refund: '收取退款',
    order_over_payment: '訂單超額支付',
    pay_tip:
      '提示：付款已確認，該筆訂單已完成，商戶會盡快進行交易處理。充值到賬數額以具體到賬金額為準，',
  },
  // 复制
  copy: {
    copied: '已復制',
    copied2: '付款地址已複製',
    order_no: '訂單編號已複製',
  },
  // 币种
  currency: {
    choose_currency_tip: '請從以下貨幣選項中選擇一個以完成您的支付',
  },
  country: {
    ar: '阿拉伯文',
    de: '德文',
    en: '英文',
    es: '西班牙文',
    fr: '法文',
    hi: '印地語',
    id: '印尼文',
    it: '義大利文',
    ja: '日文',
    ko: '韓文',
    pt: '葡萄牙文',
    ru: '俄文',
    th: '泰文',
    tr: '土耳其文',
    vi: '越南文',
    zh_cn: '中文',
    zh_hk: '繁體中文',
  },
  errCode: {
    1000000: '用戶/商戶ID不能為空',
    1000001: '用户/商户API-key不能为空',
    1000002: '用戶/商戶平台唯一訂單編號不能為空',
    1000003: '用戶/商戶不存在,請檢查ID或API-key',
    1000004: '訂單已存在,請檢查訂單編號',
    1000005: '訂單不存在,請檢查訂單編號',
    1000006: '請檢查配置參數',
    1000007: '付款金額不能為空',
    1000008: '付款金額不能小於0',
    1000009: '幣種編號不能為空',
    1000010: '暫無地址可分配，請聯繫管理員',
    1000011: '郵箱格式錯誤',
  },
  header_menu: {
    logout: '退出',
  },
};
