import { createApp, provide } from 'vue';
import App from './App.vue';

import router from './router/index';
import store from './store/index';
// import setupElementPlus from './plugins/element';
// import ElementPlus from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import './utils/rem';
import { utils } from './utils/util';
import http from './utils/http';
import { i18n, vantLocales } from './locale';
import directives from './utils/directive';

// vantLocales(i18n.locale)

const app = createApp(App);
// setupElementPlus(app);
// app.use(ElementPlus)
app.directive('fixed', directives.fixed());
app.use(Vant);
app.use(i18n);
// app.config.globalProperties.$util = utils;
app.provide('$utils', utils);
app.provide('http', http);
app.use(router).use(store).mount('#app');

