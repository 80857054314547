// 多语言
export const LANGUAGE_TYPE = 'LANGUAGE_TYPE';
// 获取支付信息
export const GET_PAY_INFO_DATA = 'GET_PAY_INFO_DATA';
// 订单详情
export const ORDER_INFO_DETAIL = 'ORDER_INFO_DETAIL';
// 订单详情
export const CREATED_ORDER_INFO = 'CREATED_ORDER_INFO';
// 币种 logo
export const CURRENCY_LOGO = 'CURRENCY_LOGO';
// USDT 币种列表
export const USDT_CURRENCY_LIST = 'USDT_CURRENCY_LIST';
// 是否是 usdt
export const SET_IS_USDT = 'SET_IS_USDT';
// 重新支付
export const PAY_AGAIN = 'PAY_AGAIN';
