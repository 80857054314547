import axios from 'axios';
import { Toast } from 'vant';
// import { payUrl } from '@/utils/config';
import { baseUrl } from './../config';

const service = axios.create({
  withCredentials: true,
  timeout: 20000,
  baseURL: baseUrl,
  /*
  baseURL: '',
 */
  // TODO 打包前注意修改
  // http://192.168.2.154:8809
  // baseURL: 'https://test.dpay.pro', // 本地开发使用，预发可为空
  // baseURL: 'https://api.dpay.pro', // 线上打包使用
});
// // axios的全局配置
// instance.defaults.headers.post = {
// headers: { 'Content-Type': 'application/json' }
//   'Content-Type': 'application/x-www-form-urlencoded'
// }
// 返回res.data的interface
interface IResponse {
  code: number | string;
  data: any;
  msg: string;
}
// async login(params: LoginParams): Promise<HttpResponse> {
//   return Axios('/api/user', {
//     method: 'get',
//     responseType: 'json',
//     params: {
//       ...params
//     },
//   })
// }
// https://segmentfault.com/a/1190000039806000
let url = '';
let hasOrder = false;

service.interceptors.request.use(
  (config) => {
    const token: string | null = sessionStorage.getItem('tk') || '';
    if (config.url != '/api/v1/pay/bpayUrl' && token) {
      config.headers['authorization'] = JSON.parse(token);
    }
    url = config.url;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  // 响应包含以下信息data,status,statusText,headers,config
  (res) => {
    const { data, status } = res;
    if (status === 200) {
      const { code, msg } = data;
      if (code === 0) {
        return Promise.resolve(data);
      } else {
        // Toast.fail(msg);
        if(code == 401){
          // token is null
          Toast.fail(msg)
          let timerId = setTimeout(() => {
            timerId = null
            const payUrl = JSON.parse(sessionStorage.getItem('vuex'))?.getPayInfoData.callbackUrl
            window.location.href = payUrl
            clearTimeout(timerId)
          }, 1000);
          return
        }
        const errorCode = JSON.parse(sessionStorage.getItem('errorCode'));
        const { languageType } = JSON.parse(sessionStorage.getItem('vuex'));
        languageType && Toast.fail(errorCode[languageType][code] || msg);
        // 订单不存在
        if(code == '1000005' && !hasOrder){
          hasOrder = true
          service.post('/api/v1/merchantInfo/baseUrl').then((res: any) => {
            if (res.code === 0) {
              hasOrder = false
              window.open(res.data);
            }
          });
        }
        return Promise.resolve(data);
      }
    } else {
      return Promise.reject(res);
    }
  },
  (err) => {
    const { response } = err;
    console.log('网络请求失败！', err);
    // 这个接口不执行网络异常的报错
    if (url == '/api/v1/pay/orderInfo') {
      return;
    }
    if (response) {
      errorHandle(response.status, response.statusText);
      return Promise.reject(response);
    } else {
      Toast.fail('network error！');
    }
    return true;
  }
);

const errorHandle = (status: number, other: any = 'error') => {
  switch (status) {
    case 400:
      Toast.fail('network error');
      break;
    case 401:
      Toast.fail('network error');
      break;
    case 403:
      Toast.fail('network error');
      break;
    case 404:
      Toast.fail('network error');
      break;
    default:
      Toast.fail(other);
      break;
  }
};

export default { service };
